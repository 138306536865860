import React from "react";
import { useConnect } from "wagmi"
import { useConnectWalletModalActions, useIsConnectWalletModalOpen } from "./zustan"
import { Modal, ModalBody } from "reactstrap";
import { connect } from "react-redux";
import MetaMask_Fox_2 from '../../assets/images/MetaMask_Fox_2.png';
import wallet_con from '../../assets/images/WalletConnect_icon.png';
import { toast } from "react-toastify";

const ConnectMeta = () => {

    const { connectAsync, connectors } = useConnect()
    const isConnectWalletModalOpen = useIsConnectWalletModalOpen()
    const { toggleConnectWalletModal } = useConnectWalletModalActions()

    const onWalletClick = async (connector) => {

        toggleConnectWalletModal();

        try {
            if (connector.ready == false) {
                toast.warn(`Please add ${connector.name} extension in your browser`);
            } else {
                const res = await connectAsync({ connector });
                if (!res) return null
                else return res;
            }
        } catch (e) {
            console.log('e: ', e);
            toast.error(e.message);
        }
    };

    return (
        <div>
            <Modal
                isOpen={isConnectWalletModalOpen} toggle={toggleConnectWalletModal}
                className="signin_model_wrap model_common_style_wrap"
                centered
            >
                <ModalBody>
                    <div className="text-center">
                        <h4 className="text-white">
                            Connect Wallet
                        </h4>
                    </div>
                    <div className="close_model" onClick={toggleConnectWalletModal}>
                        x
                    </div>

                    <div className="cenctWllt_wrap">

                        <button type="button" onClick={() => onWalletClick(connectors[0])}>
                            <img src={MetaMask_Fox_2} width={45} alt="meta_mask" />
                            MetaMask
                        </button>
                        <button type="button" onClick={() => onWalletClick(connectors[1])}>
                            <img src={wallet_con} alt="wallet_connect" />
                            Wallet Connect
                        </button>
                    </div>
                </ModalBody>
            </Modal>
        </div>
    )
}

const mapDispatchToProps = (dispatch) => ({
    Joinnewuser: (data) => dispatch({ type: "JOIN_USER", data }),
})

export default connect(null, mapDispatchToProps)(ConnectMeta);