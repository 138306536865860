import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter } from "react-router-dom";
import { Provider } from "react-redux";
import { SocketContext, socket } from "./context/socket";
import { ContextProvider } from "./context/ContextProvider";
import { WagmiConfig } from "wagmi";
import { client, config } from "./Pages/Walletconnection/walletconnect";
import store from "./Redux/store";
import './Language/i8nxt'
import 'aos/dist/aos.css';
import "bootstrap/dist/css/bootstrap.css";
import "react-toastify/dist/ReactToastify.css";


const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <SocketContext.Provider value={socket}>
    <Provider store={store}>
      <WagmiConfig config={config}>
        <ContextProvider>
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </ContextProvider>
      </WagmiConfig>
    </Provider>
  </SocketContext.Provider>
);

reportWebVitals();
