import React, { useState, useEffect } from "react";
import upload_icon from "../assets/images/upload.png";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { PageLoader } from "./shared/loader";
import { toast } from "react-toastify";

const format = ["image/jpg", "image/png", "image/jpeg"];

function Contactus({
  commonApiCall,
  categoryRes,
  getprofile,
  loading,
}) {

  const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm({ mode: "onChange", });

  const [upload, setUploadimg] = useState();
  const [preview, setPreview] = useState(null);
  const [isselect, setIsSelected] = useState(false);
  const { t } = useTranslation();

  const changeHandler = (e) => {

    const file = e.target.files[0];
    if (!format.includes(file.type)) {
      toast.error("JPG,PNG,JPEG format only allowed");
      return
    }

    if (format.includes(file.type)) {
      setUploadimg(e.target.files[0]);
      const reader = new FileReader();
      reader.addEventListener("load", () => {
        setPreview(reader.result);
      });
      reader.readAsDataURL(e.target.files[0]);
    }

    setIsSelected(true);
  };

  const handleKeyDown = (e) => {
    if (e.key === " " && e.target.value?.length === 0) {
      e.preventDefault();
    }
  };

  useEffect(() => {
    document.title = `HouseofPlinko | ${t("Help center")}`;

    window.scrollTo(0, 0)
    const getCategory = {
      url: "getcategory",
      method: "get",
      reducerName: "categoryRes",
    };
    commonApiCall(getCategory);
  }, []);

  useEffect(() => {
    if (getprofile?.status) setValue("email", getprofile?.data?.email)
  }, [getprofile])

  const onSubmit = (data) => {

    let receipt = new FormData();
    receipt.append("email", data.email);
    receipt.append("contact_img", upload);
    receipt.append("category", data.category);
    receipt.append("description", data.description);

    const Data = {
      url: "user/saveContact",
      method: "post",
      reducerName: "contactRes",
      payload: receipt,
      type: "formdata"
    };

    commonApiCall(Data, (res) => {
      if (res.status) {
        reset(); setIsSelected(false);
        setUploadimg("");
        setPreview(null);
      }
    });
  };


  return (
    <>
      <PageLoader load={loading} />

      <div className="profile_section_outer_wrap px-4 minHght">
        <h3 className="page_title_wrap">{t("Contact Us")}</h3>
        <div className="pilnko_tabs_outer_wrap">
          <div className="profile_content_outer_wrap wallet_content_outer_wrap animated_div">
            <form
              className="profile_update_content_outer_wrap wallet_content_inner_wrap"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete={"off"}
            >
              <div className="contact_input_wrap align-items-start">
                <div className="contact_form_group">
                  <label>{t("Email ID")}</label>
                  <div className="input_group_wrap">
                    <input
                      type="text"
                      placeholder="Enter Your Email Id"
                      name="email"
                      {...register("email", {
                        required: t("emailreq"),
                        pattern: {
                          value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                          message: t("emailpat"),
                        },
                      })}
                    />
                    {errors.email && (
                      <p className="text-danger">{errors.email.message}</p>
                    )}
                  </div>
                </div>
                <div className="contact_form_group">
                  <div className="risk_row_input_outer_wrap">
                    <div className="form-group">
                      <label>{t("Subject")}</label>
                      <select name="category"
                        {...register("category", {
                          required: t("catreq"),
                        })}
                      >
                        <option value="">{t("Select Subject")}</option>
                        {categoryRes &&
                          categoryRes.data.map((item) => (
                            <option>{item.categoryname}</option>
                          ))}
                      </select>
                      {errors.category && (
                        <p className="text-danger">{errors.category.message}</p>
                      )}
                    </div>
                  </div>
                </div>

                <div className="contact_form_group">
                  <label>{t("Description")}</label>
                  <div className="input_group_wrap">
                    <textarea
                      placeholder="Enter Your Description"
                      name="description"
                      className="text-white"
                      onKeyDown={handleKeyDown}
                      {...register("description", {
                        required: t("desreq"),
                      })}
                    />
                    {errors.description && (
                      <p className="text-danger">{errors.description.message}</p>
                    )}
                  </div>
                </div>
                <div className="contact_form_group contact_upload_outer_wrap">
                  <label>{t("Attachment")}</label>
                  <label htmlFor="contact_upload" className="upload_label">
                    <img src={preview ? preview : upload_icon} alt="upload_icon" />
                    {isselect ? <>{upload.name}</> : "Upload"}
                  </label>
                  <div className="input_upload">
                    <input
                      type="file"
                      name="contact_img"
                      id="contact_upload"
                      onChange={changeHandler}
                    />
                  </div>
                </div>

                <div className="wallet_withdraw_confirm">
                  <button type="submit" className="copy_address">
                    {t("Submit")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}

const mapStateToProps = (state) => ({
  loading: state.loading,
  contactRes: state.contactRes,
  categoryRes: state.categoryRes,
  getprofile: state.getprofile,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) => dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Contactus);
