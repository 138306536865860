export const Pin = (
  position,
  radius,
  color,
  shadowColor,
  shadowBlur,
  shadowOffsetX,
  shadowOffsetY,
  soundCount
) => ({
  position,
  radius,
  color,
  shadowColor,
  shadowBlur,
  shadowOffsetX,
  shadowOffsetY,
  soundCount,
}); 
