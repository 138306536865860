import React, { useEffect } from 'react'
import { PLINKO_PAYOUT_DIVIDER } from '@dicether/state-channel'
import ClassNames from 'classnames'
import './PayoutTable.scss'
import { Style1 } from './plinko_style'
import { getExactPosition } from './payout_value'
import ReactTooltip from 'react-tooltip'
import { randomObj } from './random_rows'
import { CustomUseContext } from '../../context/ContextProvider'

const PayoutTable = React.memo(
	({ payout, showResult, resultColumnData, startPos, rows }) => {
		const { amounValueInfo } = CustomUseContext()
		useEffect(() => {
			ReactTooltip.rebuild()
		}, [rows])
		let resultColumn = getExactPosition(startPos, rows, resultColumnData)
		const len = payout?.length
		const totalPayout = [...payout.slice(1).reverse(), ...payout]
		const color = [...Array(len).keys()].map(
			x => Style1[`colorStrip-${(len - 1) * 2}-${x}`]
		)
		const totalColor = [...color.slice(1).reverse(), ...color]
		return (
			<div className={Style1.payoutTable}>
				{totalPayout.map((value, index) => (
					<>
						<ReactTooltip
							place='top'
							type='light'
							id={'info' + index}
							effect='float'
						>
							<div className='row'>
								<div className='col-md-5'>
									<span>Win amount</span>
									<br />
									<span>
										{(value / PLINKO_PAYOUT_DIVIDER) * amounValueInfo}
									</span>
								</div>
								<div className='col-md-2'>
									<span className='hdSpt1'>|</span>
								</div>

								<div className='col-md-5'>
									<span>Win chance</span>
									<br />
									<span>
										{(index === 8 && rows === 8) ||
										(index === 12 && rows === 12) ||
										(index === 16 && rows === 16)
											? ((1 / randomObj[rows]?.length) * 100).toFixed(4)
											: (
													(randomObj[rows].filter(item => item == index)
														?.length /
														randomObj[rows]?.length) *
													100
											  ).toFixed(4)}{' '}
										%
									</span>
								</div>
							</div>
						</ReactTooltip>
						<PayoutInfo
							key={`${len}-${index}`}
							showResult={showResult && resultColumn === index}
							multiplier={value / PLINKO_PAYOUT_DIVIDER}
							colorclassName={totalColor[index]}
							rows={rows}
							ind={index}
						/>
					</>
				))}
			</div>
		)
	}
)

const PayoutInfo = ({ showResult, colorclassName, multiplier, rows, ind }) => {
	const classNamesColorStrip = ClassNames(Style1.colorStrip, colorclassName, {
		[Style1.colorStrip_show]: showResult
	})
	const classNamesEntry = ClassNames(Style1.resultEntry, {
		[Style1.resultEntry_show]: showResult
	})
	return (
		<>
			<div
				data-tip
				data-for={'info' + ind}
				currentitem='false'
				className={`${classNamesEntry} ${
					rows == 8 ? 'eightrows' : rows == 12 ? 'twl' : 'sixteenrows'
				} cursor`}
			>
				<div className={classNamesColorStrip} />
				<span className={Style1.multiplier}>{`${multiplier}x`}</span>
			</div>
		</>
	)
}
export default PayoutTable
