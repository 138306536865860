import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import trophy from "../assets/images/trophy.png";
import error_icon from "../assets/images/error_icon.png";
import money_bag from "../assets/images/money_bag.png";
import statistic_icon from "../assets/images/3d_icon.png";
import bar_chart from "../assets/images/bar_chart.png";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";

import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { PageLoader } from "./shared/loader";
import Aos from "aos";

const Statistics = ({ commonApiCall, getgames, loading, }) => {

  const [statistics, setStatistics] = useState();
  const [currencyData, setCurrencyData] = useState([]);
  const [selectedDatas, setSelectedDatas] = useState({
    game_name: "Plinko",
    currencyname: "ETH",
    Decimal: 18,
  });

  const { t } = useTranslation();
  const [activeGames, setActiveGames] = useState([]);

  useEffect(() => {
    Aos.init({ once: true, duration: 200 })
    document.title = `HouseofPlinko | ${t("Statistics")}`;

    if (selectedDatas.currency_id && selectedDatas.game_id) {
      const Data = {
        url: "user/getstatistics",
        method: "post",
        reducerName: "getstatistics",
        payload: selectedDatas,
      };
      commonApiCall(Data, (response) => {
        if (response?.status) setStatistics(response.data);
      });
    }
  }, [selectedDatas]);

  useEffect(() => {
    const Data = {
      url: "getcurrency_det",
      method: "post",
      reducerName: "getcurrency_det",
      payload: {},
    };
    commonApiCall(Data, (response) => {
      if (response?.data) {
        setCurrencyData(response.data);
        setSelectedDatas((selectedDatas) => ({
          ...selectedDatas,
          currency_id: response.data[0]._id,
          Decimal: response.data[0].decimal,
        }));
      }
    });
  }, []);

  useEffect(() => {
    if (getgames?.data) {
      let active_games = getgames.data.filter((item) => item.status === 0);
      setActiveGames(active_games);
    }
  }, [getgames]);

  useEffect(() => {
    const Data = {
      url: "getgames",
      method: "post",
      reducerName: "getgames",
    };
    commonApiCall(Data, (response) => {
      if (response?.data) {
        setSelectedDatas((selectedDatas) => ({
          ...selectedDatas,
          game_id: response.data.find(
            (item) => item.game_name === selectedDatas.game_name
          )._id,
        }));
      }
    });
  }, []);

  return (
    <><PageLoader load={loading} />
      <div className="statistics_section_outer_wrap">
        <div className="statistics_dropdown">
          <h3 className="page_title_wrap">{t("Statistics")} :</h3>
          <UncontrolledDropdown className="top_coin_dropdown_wrap">
            <DropdownToggle>
              <img src={selectedDatas.icon} width={18} /> {selectedDatas.game_name}
            </DropdownToggle>
            <DropdownMenu>
              {activeGames?.length > 0 &&
                activeGames.map((element, index) => (
                  <DropdownItem key={index}
                    onClick={() => {
                      setSelectedDatas({
                        ...selectedDatas,
                        game_id: element._id,
                        game_name: element.game_name,
                        icon: element.icon,
                      });
                    }}
                  >
                    <img src={element.icon} width={18} /> {element.game_name}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>

          <UncontrolledDropdown className="top_coin_dropdown_wrap">
            <DropdownToggle>
              <img src={selectedDatas?.image} width={18} /> {selectedDatas?.currencyname}
            </DropdownToggle>
            <DropdownMenu>
              {currencyData?.length > 0 &&
                currencyData.map((element, index) => (
                  <DropdownItem key={index} onClick={() => {
                    setSelectedDatas({
                      ...selectedDatas,
                      currency_id: element._id,
                      currencyname: element.currency_name,
                      Decimal: element.decimal,
                      image: element.image,
                    });
                  }}>
                    <img src={element.image} width={18} /> {element.currency_name}
                  </DropdownItem>
                ))}
            </DropdownMenu>
          </UncontrolledDropdown>
        </div>

        {statistics ? (
          <div className="statistics_info_outer_wrap">
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={trophy} alt="trophy" />
                  <h6>{t("Number of Wins")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>{statistics.wins || 0}</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={error_icon} alt="error_icon" />
                  <h6>{t("Number of Loss")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>{statistics.loss || 0}</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={money_bag} alt="money_bag" />
                  <h6>{t("Total Profit Earned")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>
                    {statistics.total_profit.toFixed(6) || "0.000000"}
                  </h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={statistic_icon} alt="statistic_icon" />
                  <h6>{t("Biggest win by profit")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>{statistics.bigges_win_profit || 0}</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={bar_chart} alt="bar_chart" />
                  <h6>{t("Lowest win by profit")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>{statistics.lowest_win_profit || 0}</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={bar_chart} alt="bar_chart" />
                  <h6>{t("Completed Game")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>{statistics.no_of_playes || 0}</h6>
                </div>
              </div>
            </div>
          </div>
        ) : (
          <div className="statistics_info_outer_wrap">
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={trophy} alt="trophy" />
                  <h6>{t("Number of Wins")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>0</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={error_icon} alt="error_icon" />
                  <h6>{t("Number of Loss")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>0</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={money_bag} alt="money_bag" />
                  <h6>Total Profit Earned</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>0</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={statistic_icon} alt="statistic_icon" />
                  <h6>{t("Biggest win by profit")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>0</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={bar_chart} alt="bar_chart" />
                  <h6>{t("Lowest win by profit")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>0</h6>
                </div>
              </div>
            </div>
            <div className="statistic_info_inner_wrap" data-aos="zoom-out">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={bar_chart} alt="bar_chart" />
                  <h6>{t("Completed Game")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>0</h6>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  getstatistics: state.getstatistics,
  getgames: state.getgames,
  loading: state.loading,
  getonestatistics: state.getonestatistics,
  getcurrency_det: state.getcurrency_det,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) =>
    dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Statistics);
