export const NAME = "POTATO PLINKO";
export const ACCOUNT_BALANCE_POLL_INTERVAL = 5000;
export const FROM_WEI_TO_BASE = 1e9;
export const FROM_BASE_TO_WEI = 1e9;
export const NETWORK_NAME = "Main";
export const MIN_GAME_SESSION_VALUE = 1e7;
export const MAX_GAME_SESSION_VALUE = 30e9;
export const HOUSE_EDGE = 150;
export const HOUSE_EDGE_DIVISOR = 10000;
export const MIN_BET_VALUE = 1e4;
export const MAX_BET_VALUE = 4e9;
export const MIN_BANKROLL = 9e9;
export const KELLY_FACTOR = 1;
export const RANGE = 100;
export const SESSION_TIMEOUT = 48; 