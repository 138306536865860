import { formatEther, formatUnits } from "viem"
import { useAccount, useBalance } from "wagmi"
import { readContract, writeContract } from '@wagmi/core'
import { toast } from "react-toastify"


export const useGetNativeCoinBalance = (chainId) => {
    const { address: account } = useAccount()
    const { status, refetch, data } = useBalance({
        chainId,
        address: account,
        enabled: !!account
    })

    return {
        balance: data?.value ? parseFloat(formatEther(data.value)).toFixed(6) : 0,
        fetchStatus: status,
        refresh: refetch
    }
}

export const readcontract = async (abi, address, fnname, args, chain) => {
    try {
        let data = await readContract({ address: address, abi: abi, functionName: fnname, args: args, chainId: chain });
        let decimal = await readContract({ address: address, abi: abi, functionName: "decimals" });

        return parseFloat(formatUnits(data, decimal))
    }
    catch (err) {
        toast.error("Something went wrong")
        console.error(err);
        return null
    }
}

export const approveToken = async (abi, tokenaddress, fnname, args, address) => {
    try {
        let data = await writeContract({ address: tokenaddress, abi: abi, functionName: fnname, args: args, account: address });

        return data
    }
    catch (err) {
        toast.error("Transaction failed.")
        console.error(err);
        return null
    }
}

export const depositetoken = async (abi, contractAddress, args, address, value) => {
    try {
        let data = await writeContract({ address: contractAddress, abi: abi, functionName: "deposit", args: args, account: address, value: value });
        return data;
    }
    catch (err) {
        toast.error("Transaction failed.")
        console.error(err);
        return null
    }
}


// Withdraw

export const getSigner = async (abi, address, fnname) => {
    try {
        let data = await readContract({ address: address, abi: abi, functionName: fnname });
        return data;
    }
    catch (err) {
        toast.error("Transaction failed.")
        console.error(err);
        return null
    }
}

export const withdrawToken = async (abi, contractAddress, args, address) => {
    try {
        let data = await writeContract({ address: contractAddress, abi: abi, functionName: "withdraw", args: args, account: address });
        return data;
    }
    catch (err) {
        toast.error("Transaction failed.")
        console.error(err);
        return null
    }
}