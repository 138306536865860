import React, { useState, useEffect, useContext } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import dollar from "../assets/images/dollar.png";
import creditcard from "../assets/images/creditcard.png";
import play from "../assets/images/pics/play.gif";
import profit from "../assets/images/profit.png";
import Helper from "../Helper/Helper";
import { useTranslation } from "react-i18next";

import { Helmet } from "react-helmet";
import "../style.css";
import { Modal, ModalBody } from "reactstrap";
import { toast } from "react-toastify";

import hphome from "../assets/images/hp-menu-home.png";
import hpdeposit from "../assets/images/hp-menu-deposit.png";
import hpwithdraw from "../assets/images/hp-menu-withdraw.png";
import hpswap from "../assets/images/hp-menu-swap.png";
import hpgraph from "../assets/images/hp-menu-graph.png";
import Noprofile from '../assets/user.png';
import bnbIcon from '../assets/images/binancebadge.png';
import ethIcon from '../assets/images/eth.png';

import TfaVerify from "../Pages/TfaVerify";
import { modalService } from "../Pages/Moduleservice";
import Signin from "../Pages/SignIn";
import SignUp from "../Pages/SignUp";
import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faSignOut } from "@fortawesome/free-solid-svg-icons";
import { CustomUseContext } from "../context/ContextProvider";

import { useAccount, useDisconnect, useNetwork } from "wagmi";
import hplogo from "../assets/images/hp-logo.png";
import { SocketContext } from "../context/socket";
import ConnectMeta from '../Pages/zus/connectModal';
import OtpVerify from "../Pages/otpVerify";
import VerifyMail from "../Pages/verifyMail";
import { MultiLang } from "./lang";

const Header = ({
  tfaVerifyRes,
  signInResponse,
  getprofile,
  commonApiCall,
  resetRes,
  depositmetamaskResponse,
  recommendRes,
  clearstate,
  signUpResponse,
  depositwalletResponse,
  logotpRes
}) => {


  const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ mode: "onChange", });
  const navigate = useNavigate();
  const { disconnect } = useDisconnect();

  const loginToken = localStorage.getItem("hop-access-key");
  const socket = useContext(SocketContext);
  const { t } = useTranslation();
  const { isConnected, address } = useAccount();
  const { chain, chains } = useNetwork();


  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [logged, setLogged] = useState(false);
  const {
    headerSlider,
    selectedCurrency,
    setSelectedCurrency,
    setUpdatedBalance,
    setWalletBalance,
    sidebar,
    setSignupmodal,
    signupmodal,
    setLoginmodal,
    loginmodal,
    setDecimal,
    betmodal,
    gamemodal, setGamemodal,
    bettoggle,
    betslipstate,
    SetBetslipstate,
    setUserToken,
    setSitesettings,
    setotpModal,
    otpModal,
    mailModal, setmailModal
  } = CustomUseContext();

  const logoff = () => {
    socket.emit("logout", loginToken);
    disconnect();
    localStorage.removeItem("hop-access-key");
    setUserToken(null);
    navigate('/')
  };


  let currentUrlParams = new URLSearchParams(window.location.search);
  let Reset = currentUrlParams.get("resetCode");

  // signup Model
  const signuptoggle = () => {
    setLoginmodal(false);
    setSignupmodal(false);
  };
  const mailVerifyToggle = () => setmailModal(!mailModal);

  const [verifymodal, setVerifymodal] = useState(false);
  const verifytoggle = () => {
    setVerifymodal(!verifymodal);
  };

  // currencyIcon;

  const logintoggle = () => setLoginmodal(!loginmodal);
  const signup = () => setSignupmodal(!signupmodal);
  const otptoggle = () => setotpModal(!otpModal);

  const gametoggle = () => setGamemodal(!gamemodal);

  // Rest Model
  const [restmodal, setRestmodal] = useState(false);
  const resttoggle = () => {
    setRestmodal(!restmodal);
  };
  const [currencyicon, SetCurrencyicon] = useState();
  const [getsitesettings, setGetsitesettings] = useState();

  useEffect(() => {
    const Data = {
      url: "getgames",
      method: "post",
      reducerName: "recommendRes",
      payload: { type: "user" },
    };
    commonApiCall(Data);

    const play = {
      url: "getcurrency_det",
      method: "post",
      reducerName: "currencyIcon",
    };
    commonApiCall(play, (response) => {
      if (response) {
        SetCurrencyicon(response?.data);
      }
    });

    const Data1 = {
      url: "getSettings",
      method: "get",
      reducerName: "getSitesettings",
    };
    commonApiCall(Data1, (response) => {
      if (response) {
        setSitesettings(response?.data)
        setGetsitesettings(response.data);
      }
    });


  }, []);

  const betslipdata = (res) => {
    if (!res.from) {
      const data12 = {
        bet_id: res.bet_id,
        user_id: res.user_id,
        game_id: res.game_id,
      };
      const Data = {
        url: "betslip",
        method: "post",
        reducerName: "betslipres",
        payload: data12,
      };
      commonApiCall(Data, (response) => {
        if (response) {
          SetBetslipstate(response?.data[0]);
        }
      });
    }
  };

  const currency = (res) => {
    let getLogo = currencyicon?.filter(function (el) {
      if (el._id === res) {
        return el;
      }
    });
    return getLogo && getLogo[0]?.image;
  };

  useEffect(() => {
    if (selectedCurrency) {
      setUpdatedBalance("");
    }
  }, [selectedCurrency]);


  useEffect(() => {
    let dataa = {
      forgotten_password_code: Reset,
    };
    const Data123 = {
      url: "user/forgot_usercheck",
      method: "post",
      reducerName: "forgetpasscheck",
      payload: dataa,
    };
    if (Reset) {
      commonApiCall(Data123, (response) => {
        if (response) {
          if (response?.status) {
            if (!loginToken) {
              resttoggle();
            } else {
              toast.info("You have already logged in");
            }
          } else {
          }
        } else {
        }
      });
    }
  }, [Reset]);

  useEffect(() => {
    if (loginToken) {
      const Data = {
        url: "user/getUserdetails",
        method: "post",
        reducerName: "getprofile",
      };
      commonApiCall(Data);
    }
  }, [loginToken]);

  useEffect(() => {
    if (loginToken) {
      const Data = {
        url: "user/WalletBalance",
        method: "get",
        reducerName: "walletbalance",
      };
      commonApiCall(Data, (response) => {
        if (response) {
          setWalletBalance([...response]);
          setSelectedCurrency({ ...response[0], image: ethIcon });
          setDecimal(response[0]?.decimal);
        }
      });
    }
  }, [loginToken, depositmetamaskResponse, depositwalletResponse]);

  const location = useLocation();

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const togglePasswordVisiblity = (val) => {
    if (val === "oldpwd") {
      setPasswordShown(passwordShown ? false : true);
    } else if (val === "newpwd") {
      setPasswordShown1(passwordShown1 ? false : true);
    }
  };

  const onSubmit = (data) => {
    const verifyReset = {
      forgot_code: Reset,
      password: data.password,
      confirm_password: data.confirm_password,
    };

    const Data = {
      url: "user/reset_password",
      method: "post",
      reducerName: "resetRes",
      payload: verifyReset,
    };

    if (Reset) {
      commonApiCall(Data, (response) => {
        if (response?.status) {
          resttoggle();
        } else {
        }
      });
      reset();
    }
  };

  // useEffect(() => {
  // if (logotpRes) {
  //   if (logotpRes?.status) {
  //     setLogged(true);
  //     setUserToken(logotpRes.data.login_id);
  //     localStorage.setItem("hop-access-key", logotpRes.data.login_id);
  //     otptoggle();

  //     if (getsitesettings?.popup_status) gametoggle();
  //     else navigate("/plinko?game_id=633a9319a87884818c1b3ac8")
  //     clearstate({ reducerName: "logotpRes" });
  //   }
  // }
  // }, [logotpRes])

  useEffect(() => {
    if (signInResponse) {

      localStorage.setItem("userID", signInResponse._id);
      if (signInResponse?.status) {
        logintoggle();
        if (signInResponse.tfa === "enable") verifytoggle();
        else {
          // otptoggle();
          setLogged(true);
          setUserToken(signInResponse?.data.login_id);
          localStorage.setItem("hop-access-key", signInResponse?.data.login_id);

          if (getsitesettings?.popup_status) gametoggle();
          else navigate("/plinko?game_id=633a9319a87884818c1b3ac8")
          //
        }
      } else if (!signInResponse?.status && signInResponse?.verified == false) {
        setLoginmodal(false);
        setmailModal(true);
      }
      clearstate({ reducerName: "signInResponse" });
      logintoggle();
    }

    if (signInResponse && tfaVerifyRes) {
      setLoginmodal(false);
      setVerifymodal(false);
    }
  }, [signInResponse, tfaVerifyRes, resetRes]);

  useEffect(() => {
    if (tfaVerifyRes) {
      if (tfaVerifyRes.status) {
        verifytoggle();
        localStorage.setItem("hop-access-key", tfaVerifyRes.data.token);
        setUserToken(tfaVerifyRes.data.token);
        if (getsitesettings?.popup_status) gametoggle();
        else navigate("/plinko?game_id=633a9319a87884818c1b3ac8")
        clearstate({
          reducerName: "tfaVerifyRes",
        });
      }
    }
  }, [tfaVerifyRes]);

  useEffect(() => {
    if (signUpResponse) {
      if (signUpResponse.status) {
        setSignupmodal(false);
      }
      else {
        setSignupmodal(false);
      }
      clearstate({
        reducerName: "signUpResponse",
      });
    }
  }, [signUpResponse]);

  useEffect(() => {
    const subscription = modalService
      .getMessage()
      .subscribe(({ component }) => {
        if (component === "islogin") {
          logintoggle();
        }
      });

    // gametoggle();
    return () => {
      subscription.unsubscribe();
    };
  }, []);

  const [classIsActive, setClassIsActive] = useState(true);
  const [isOpen, setIsOpen] = useState(false)
  const sideMenu = () => {
    setIsOpen(prevState => !prevState)
    setClassIsActive(!classIsActive);
    classIsActive ? document.body.classList.add('sbMenu') : document.body.classList.remove('sbMenu');
  }

  const walletDisconnect = () => {
    sideMenu();
    disconnect();
  }

  const CopytoClipboard = () => {
    navigator.clipboard.writeText(address)
    toast.success("Address copied");
  }

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{getsitesettings?.SiteName}</title>
        <meta name="description" content={getsitesettings?.metadescription} />
        <meta name="keywords" content={getsitesettings?.metakeyword} />
      </Helmet>

      <ConnectMeta />

      <header
        className={"indHeader " + (sidebar ? "sbMenu " : "")}>
        <div className="container-fluid">
          <nav className="navbar navbar-expand-lg px-0 hdrNav ">
            <div className="hdLft d-flex align-items-center">
              <div className="smClose me-3">
                <div className={isOpen ? "smClose active" : "smClose"} onClick={sideMenu}>
                  <svg id="closeicon" viewBox="0 0 800 600">
                    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
                    <path d="M300,320 L460,320" id="middle"></path>
                    <path d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190" id="bottom" transform="translate(480, 320) scale(1, -1) translate(-480, -318) "></path>
                  </svg>
                </div>
              </div>
              <Link className="navbar-brand" to="/">
                <img src={hplogo} className="img-fluid" width={100} />
              </Link>
            </div>

            <div className="hdCnt">
              <div className="hdMenu">
                <div className="sdwrp">
                  <h4 className="menutit">{t("Menu")}</h4>
                  <ul>
                    <li onClick={sideMenu}>
                      <NavLink to="/">
                        <span className="hdMuIc">
                          <img src={hphome} alt="hp-menu-home.png" />
                        </span>
                        <span className="hdMuTx">{t("Home")}</span>
                      </NavLink>
                    </li>
                    <li onClick={sideMenu}>
                      {recommendRes?.data.map((list, index) => (
                        <NavLink to={`/plinko?game_id=${list?._id}`} key={index}
                        >
                          <span className="hdMuIc">
                            <img src={list?.icon} alt="hp-menu-game.png" />
                          </span>
                          <span className="hdMuTx">{list?.game_name}</span>
                        </NavLink>
                      ))}
                    </li>
                    <li>
                      <MultiLang sideMenu={sideMenu} />
                    </li>
                    <div className="hpmenuHr"></div>
                    <h4 className="menutit">{t("Personal")}</h4>
                    <li onClick={sideMenu}>
                      <NavLink to="/CRev4SKjd53bs/1">
                        <span className="hdMuIc">
                          <img src={hpdeposit} alt="hp-menu-deposit.png" />
                        </span>
                        <span className="hdMuTx">{t("Deposit")}</span>
                      </NavLink>
                    </li>
                    <li onClick={sideMenu}>
                      <NavLink to="/CRev4SKjd53bs/2">
                        <span className="hdMuIc">
                          <img src={hpwithdraw} alt="hp-menu-withdraw.png" />
                        </span>
                        <span className="hdMuTx">{t("Withdraw")}</span>
                      </NavLink>
                    </li>
                    <li onClick={sideMenu}>
                      <NavLink to="/CRX13KxYY58sY">
                        <span className="hdMuIc">
                          <img src={hpswap} alt="hp-menu-swap.png" />
                        </span>
                        <span className="hdMuTx">{t("Transactions")}</span>
                      </NavLink>
                    </li>
                    <li onClick={sideMenu}>
                      <NavLink to="/CRiFhNLxx07vg">
                        <span className="hdMuIc">
                          <img src={hpgraph} alt="hp-menu-graph.png" />
                        </span>
                        <span className="hdMuTx">{t("Settings")}</span>
                      </NavLink>
                    </li>
                    {isConnected ?
                      <li onClick={walletDisconnect}>
                        <NavLink to="/">
                          <span className="hdMuIc">
                            <FontAwesomeIcon icon={faSignOut} />
                          </span>
                          <span className="hdMuTx">{t("Disconnect")}</span>
                        </NavLink>
                      </li> : null}
                  </ul>
                </div>
              </div>

              {(location.pathname === "/plinko" ||
                location.pathname === "/bets") && (
                  <>
                    {(logged || loginToken) &&
                      location.pathname === "/plinko" && (
                        <div className="header_bets_button_outer_wrap">
                          <div className="header_bets_button_left_wrap">
                            {headerSlider?.length > 0 &&
                              headerSlider.map((item, index) => (
                                <button
                                  type="button"
                                  key={index}
                                  onClick={() => {
                                    bettoggle();
                                    betslipdata(item);
                                    SetBetslipstate(item);
                                  }}
                                  className={item.profit_prediction && "active"}
                                >
                                  {item.payout}x
                                </button>
                              ))}
                          </div>
                          <div className="header_bets_button_right_wrap">
                            <button
                              type="button"
                              onClick={() => navigate("/bets")}
                            >
                              {t("Bets")}
                            </button>
                          </div>
                        </div>
                      )}
                  </>
                )}
            </div>

            <div className="hdRht">
              {!loginToken ? (
                <>
                  <button className="btn btnTrs btn-9273"
                    onClick={logintoggle}
                  >
                    {t("Login")}
                  </button>
                  <button className="btn btnPrp btn-11438 btn-11438Mob"
                    onClick={signup}
                  >
                    {t("Register")}
                  </button>
                </>
              ) : (
                <div className="after_login_profil_outer_wrap">
                  <nav className="nav">
                    <ul className="nav__menu">
                      {isConnected &&
                        <li className="nav__menu-item">
                          <div className="total_matic_score_wrap">
                            <div className="bold d-flex align-items-center px-md-2" onClick={CopytoClipboard}>
                              <img src={chain?.id == 56 ? bnbIcon :
                                chain?.id == 1 ? ethIcon : null} className="mx-1" />
                              <span className="d-none d-md-block">{address?.slice(0, 6) + "..." + address?.slice(-5)}</span>
                            </div>
                          </div>
                        </li>}

                      <li className="nav__menu-item">
                        <button className="text-white bold d-flex align-items-center px-md-2">
                          <img src={getprofile?.data?.details?.profile_picture || Noprofile}
                            alt="profile" className="nav-img"
                          />
                          <span className="d-none d-md-block">{getprofile?.data?.details?.username}</span>
                        </button>

                        <ul className="nav__submenu">
                          <li className="nav__submenu-item" onClick={() => navigate('/CRiFhNLxx07vg')}>
                            <span>{t("Profile")}</span>
                          </li>
                          <li className="nav__submenu-item" onClick={logoff}>
                            <span>{t("Logout")}</span>
                          </li>
                        </ul>

                      </li>
                    </ul>
                  </nav>
                </div>
              )}

            </div>
          </nav>
        </div>
      </header>

      <Modal
        isOpen={otpModal}
        toggle={otptoggle}
        backdrop={false}
        className="signin_model_wrap model_common_style_wrap"
        centered
      >
        <div>
          <div className="close_model" onClick={otptoggle}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={hplogo} alt="hplogo" width={100} />
          </div>
          <OtpVerify setLoginmodal={setLoginmodal} loginmodal={loginmodal} />
        </div>
      </Modal>

      <Modal
        isOpen={loginmodal}
        toggle={logintoggle}
        backdrop={false}
        className="signin_model_wrap model_common_style_wrap"
        centered
      >
        <div>
          <div className="close_model" onClick={logintoggle}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={hplogo} alt="hplogo" width={100} />
          </div>
          <Signin
            setSignupmodal={setSignupmodal}
            signupmodal={signupmodal}
            setLoginmodal={setLoginmodal}
            loginmodal={loginmodal}
          />
        </div>
      </Modal>

      {/* TFA Verfiy */}

      <Modal
        isOpen={verifymodal}
        toggle={verifytoggle}
        backdrop={false}
        className="signin_model_wrap model_common_style_wrap"
        centered
      >
        <ModalBody>
          <div className="close_model" onClick={verifytoggle}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={hplogo} alt="hplogo" width={100} />
          </div>
          <TfaVerify />
        </ModalBody>
      </Modal>

      {/* Sign Up */}
      <Modal
        isOpen={signupmodal}
        toggle={signuptoggle}
        backdrop={false}
        className="signin_model_wrap model_common_style_wrap"
      >
        <ModalBody>
          <div className="close_model" onClick={signuptoggle}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={hplogo} alt="hplogo" width={100} />
          </div>
          <SignUp
            setSignupmodal={setSignupmodal}
            signupmodal={signupmodal}
            setLoginmodal={setLoginmodal}
            loginmodal={loginmodal}
          />
        </ModalBody>
      </Modal>

      {/* Reset Password */}
      <Modal
        isOpen={restmodal}
        toggle={resttoggle}
        backdrop={false}
        className="signin_model_wrap model_common_style_wrap" centered
      >
        <ModalBody>
          <div className="close_model" onClick={resttoggle}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={hplogo} alt="hplogo" width={100} />
          </div>
          <form
            className="sigin_form_wrap rest_password_wrap"
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="off"
          >
            <h4>{t("Reset Password")}</h4>
            <div className="form-group">
              <label htmlFor="New_password">{t("New Password")}</label>
              <input
                type={passwordShown ? "text" : "password"}
                placeholder={t("Fieldpassword")}
                id="New_password"
                name="password"
                {...register("password", {
                  required: t("passreq"),
                  minLength: {
                    value: 8,
                    message: t("newpassmin"),
                  },
                  pattern: {
                    value:
                      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})*/,
                    message: t("newpasspattern"),
                  },
                })}
                onKeyDown={handleKeyDown}
              />
              <div className="show-button">
                {passwordShown ? (
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => togglePasswordVisiblity("oldpwd")}
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    style={{ cursor: "pointer" }}
                    onClick={() => togglePasswordVisiblity("oldpwd")}
                  />
                )}
              </div>
              {errors.password && (
                <p className="text-danger">{errors.password.message}</p>
              )}
            </div>
            <div className="form-group">
              <label htmlFor="rest_con_password">{t("Confirm Password")}</label>
              <input
                type={passwordShown1 ? "text" : "password"}
                placeholder={t("Field Confirm Password")}
                id="rest_con_password"
                name="confirm_password"
                {...register("confirm_password", {
                  validate: (value) => value === getValues("password") || t("passmatch"),
                  required: t("confirmpass"),
                })}
                onKeyDown={handleKeyDown}
              />
              <div className="show-button">
                {passwordShown1 ? (
                  <FontAwesomeIcon
                    style={{ cursor: "pointer" }}
                    onClick={() => togglePasswordVisiblity("newpwd")}
                    icon={faEye}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={faEyeSlash}
                    style={{ cursor: "pointer" }}
                    onClick={() => togglePasswordVisiblity("newpwd")}
                  />
                )}
              </div>
              {errors.confirm_password && (
                <p className="text-danger">{errors.confirm_password.message}</p>
              )}
            </div>
            <div className="text-center">
              <button type="submit" className="btn btnPrp btn-11438 btn-11438Mob text-dark">
                {t("Reset Password")}
              </button>
            </div>
          </form>
        </ModalBody>
      </Modal>
      <Modal
        isOpen={betmodal}
        toggle={bettoggle}
        backdrop={false}
        className="pilnko_model_wrap model_common_style_wrap" centered
      >
        <ModalBody>
          <div className="close_model" onClick={bettoggle}>
            x{" "}
          </div>

          <div className="bet_slip_outer_wrap">
            <h3>{t("Bet Slip")}</h3>
            <h4>
              {t("Bet ID")}: <span>{betslipstate?.bet_id}</span>
            </h4>
            <h4>
              {t("By")} {betslipstate?.username} On{" "}
              {Helper.formatDated(betslipstate?.created_at)}
            </h4>
            <div className="bet_slip_info_wrap">
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={dollar} alt="amount icon" />
                  <h6>{t("Amount")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <img
                    src={currency(betslipstate?.cid)}
                    width={25}
                    alt="token"
                  />
                  <h6>
                    {betslipstate?.bet_amount?.toFixed(6)}
                  </h6>
                </div>
              </div>
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={creditcard} alt="amount icon" />
                  <h6>{t("Payout")}</h6>
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap">
                  <h6>{betslipstate?.payout}</h6>
                </div>
              </div>
              <div className="bet_slip_info_inner_wrap">
                <div className="bet_slip_amount_wrap">
                  <img src={profit} alt="amount icon" />
                  {betslipstate?.profit_prediction ? (
                    <h6>{t("Profit")}</h6>
                  ) : (
                    <h6>{t("Loss")}</h6>
                  )}
                </div>
                <div className="bet_slip_amount_wrap bet_slip_matic_wrap bet_slip_profit_wrap">
                  <img
                    src={currency(betslipstate?.cid)}
                    width={25}
                    alt="token"
                  />
                  <h6>{betslipstate?.profit.toFixed(6)}</h6>
                </div>
              </div>
            </div>
            <div className="bet_slip_playnow_wrap">
              <button
                onClick={bettoggle}
                className="btn btn-16147 btnPrp glow btnIcTx"
              >
                <span className="btnPlIc">
                  <img src={play} alt="play" />
                </span>
                <span className="btnPlTx">{t("Play Now")}</span>
              </button>
            </div>

            <div className="bet_x_wrap">
              {betslipstate &&
                betslipstate?.payouts?.map((element, index) => (
                  <button
                    key={index}
                    type="button"
                    className={betslipstate?.payout_position == index ? "active" : ""}
                  >
                    {element}
                  </button>
                ))}
            </div>
          </div>
        </ModalBody>
      </Modal>

      <Modal
        isOpen={mailModal}
        toggle={mailVerifyToggle}
        backdrop={false}
        className="signin_model_wrap forgot_model_wrap model_common_style_wrap"
        centered
      >
        <ModalBody>
          <div className="close_model" onClick={mailVerifyToggle}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={hplogo} alt="popupbg" width={100} />
          </div>
          <VerifyMail />
        </ModalBody>
      </Modal>

      <Modal
        isOpen={gamemodal}
        toggle={gametoggle}
        centered={true}
        backdrop={false}
        className="signin_model_wrap forgot_model_wrap model_common_style_wrap"
      >
        <ModalBody>
          <div className="close_model" onClick={gametoggle}>
            x
          </div>
          <div className="model_bg_wrap">
            <img src={hplogo} alt="popupbg" width={100} />
          </div>

          <div className="signin_submit">
            <button type="button" className="signin_btn_wrap" onClick={() => {
              navigate("/plinko?game_id=633a9319a87884818c1b3ac8")
              gametoggle();
            }}>
              {t("Play plinko")}
            </button>
            &nbsp;&nbsp;

            {getsitesettings?.button_status ? (
              <a href={getsitesettings?.more_games} target="_blank">
                <button type="button" className="signin_btn_wrap"
                >
                  {t("More games")}
                </button>
              </a>
            ) : null}
          </div>

        </ModalBody>
      </Modal>
    </>
  );
};

const mapStateToProps = (state) => ({
  signInResponse: state.signInResponse,
  tfaVerifyRes: state.tfaVerifyRes,
  resetRes: state.resetRes,
  getprofile: state.getprofile,
  loading: state.loading,
  walletbalance: state.walletbalance,
  signUpResponse: state.signUpResponse,
  forgetpasscheck: state.forgetpasscheck,
  recommendRes: state.recommendRes,
  betslipres: state.betslipres,
  currencyIcon: state.currencyIcon,
  depositmetamaskResponse: state.depositmetamaskResponse,
  depositwalletResponse: state.depositwalletResponse,
  logotpRes: state.logotpRes
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) => dispatch({ type: "commonApiCall", data, callback }),
  clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
