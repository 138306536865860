import { getContract as viemGetContract } from 'viem'
import { erc20ABI, usePublicClient, useWalletClient } from 'wagmi'
import { useMemo } from 'react'
import ethjson from '../contract/eth.json';

export const getContract = ({ abi, address, publicClient, signer }) => {
    const contract = viemGetContract({ abi, address, publicClient, walletClient: signer })
    return { ...contract }
}

export function useContract(address, abi) {
    const { data: walletClient } = useWalletClient()
    const publicClient = usePublicClient()

    return useMemo(() => {
        if (!address || !abi) return null

        try {
            return getContract({
                abi,
                address,
                publicClient,
                signer: walletClient ?? undefined,
            })
        } catch (error) {
            console.error('Failed to get contract', error)
            return null
        }
    }, [address, abi, walletClient, publicClient])
}

export function useTokenContract(tokenAddress) {
    return useContract(tokenAddress, erc20ABI)
}

export function usePlinkoContract(contractAddress) {
    return useContract(contractAddress, ethjson.abi)
}