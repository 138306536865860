export default function popCnt(num) {
    num = num - ((num >> 1) & 0x55555555); 
    num = (num & 0x33333333) + ((num >> 2) & 0x33333333); 
    return (((num + (num >> 4)) & 0xf0f0f0f) * 0x1010101) >> 24; 
    
}

export const Random = (array) => {
    return array[Math.floor(Math.random() * array?.length)]
}

export function sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms));
}