import { put, takeEvery } from "redux-saga/effects";
import helpers from "../../Helper/Helper.js";

const apiEndPoint = helpers.backendAPI();

function* commonApiCall({ data, callback }) {
  let result;

  yield put({ type: "SET_LOADING" });

  if (data.method == "post") {
    result = yield helpers.PostData(apiEndPoint + data.url, data.payload,data?.type);
  } else {
    result = yield helpers.GetData(apiEndPoint + data.url);
  }
  if (callback) {
    callback(result);
  }

  yield put({
    type: "RESPONSE",
    reducerName: data.reducerName,
    responseData: result,
  });
}

function* ClearState({ data }) {
  yield put({ type: "CLEAR_STATE", reducerName: data.reducerName });
}

export default function* rootSaga() {
  yield takeEvery("commonApiCall", commonApiCall);
  yield takeEvery("clearState", ClearState);
}
