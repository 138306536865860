import { create } from 'zustand'

const useConnectWalletModalStore = create((set) => ({
	isConnectWalletModalOpen: false,
	actions: {
		toggleConnectWalletModal: () =>
			set((state) => ({
				...state,
				isConnectWalletModalOpen: !state.isConnectWalletModalOpen
			})),
		closeConnectWalletModal: () =>
			set((state) => ({
				...state,
				isConnectWalletModalOpen: false
			}))
	}
}))

export const useIsConnectWalletModalOpen = () =>
	useConnectWalletModalStore((state) => state.isConnectWalletModalOpen)

export const useConnectWalletModalActions = () =>
	useConnectWalletModalStore((state) => state.actions)