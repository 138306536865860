import { Subject } from "rxjs";

const subject = new Subject();

export const modalService = {
  sendMessage: (component) => subject.next({ component }),
  getMessage: () => subject.asObservable(),
};

export const intervalService = {
  sendMessage: (component) => subject.next({ component }),
  getMessage: () => subject.asObservable(),
}
