import React, { useEffect, useState, useRef } from 'react'
import { TabContent, TabPane, Nav, NavItem, NavLink, UncontrolledDropdown, Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import ReactSlider from 'react-slider'
import infinite_icon from '../../assets/images/infinity.png'
import { NumericFormat } from 'react-number-format'
import { Modal, ModalBody } from 'reactstrap'
import { Link, useNavigate } from 'react-router-dom'
import PayoutTable from './PayoutTable'
import ethIcon from '../../assets/images/eth.png';

import { useTranslation } from 'react-i18next'

import { PLINKO_PAYOUT } from '@dicether/state-channel'
import { MIN_BET_VALUE } from './config'
import { Pin } from './pin'
import Vector from './vector'
import { Ball } from './Ball'
import { startPositions } from './lookupTables'
import { randomObj } from './random_rows'
import { Random, sleep } from './helper'
import placeBet from './placeBet'
import { getExactPosition } from './payout_value'
import win from '../../assets/sound/win.wav'
import click from '../../assets/sound/pin_touch.wav'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute, faVolumeHigh } from '@fortawesome/free-solid-svg-icons'
import { CustomUseContext } from '../../context/ContextProvider'
import { connect } from 'react-redux'
import { SocketContext } from '../../context/socket'
import { useContext } from 'react'
import { intervalService } from '../Moduleservice'

import plkophyctr from '../../assets/images/plkophyctr.gif';
import hpgamelight1 from '../../assets/images/hpgamelight1.png';
import hpgamelight2 from '../../assets/images/hpgamelight2.png';
import hpgamelogo from '../../assets/images/Mobplinko.png';
import bgSound from '../../assets/sound/swing.mp3';

import useSound from 'use-sound'
import { toast } from 'react-toastify'
import { ButtonLoader, PageLoader } from '../shared/loader'
import Helper from '../../Helper/Helper'
import Aos from 'aos'

let activeBalls = []
let animationActive = false
let accumulator = 0
let currentTime = 0
let ballAcceleration = new Vector(0, 9.81)
let k = 0.5
let stepsPerSecond = 120
let speed = 0.75
let startPos = 0
let autoBetInterval
let completedAutoBets = 0
let results = { betNum: 0, resultCol: -1, won: false, userProfit: 0 }
let startPosition = 0
let volume = !localStorage.getItem('gameVolume') ? false : true

let updatedCurrencyBalance
let updatedBalanceforbackend = 0
let intdata
let rand = -1

const backendAPI = Helper.backendAPI();

var audio = new Audio(bgSound);

function Plinko(props) {
	const navigate = useNavigate()
	const socket = useContext(SocketContext)
	const { t } = useTranslation();

	const [activeTab, setActiveTab] = useState('1')
	const toggle = tab => activeTab !== tab && setActiveTab(tab)

	const contextRef = useRef(null)
	const [canvasInfo, setCanvasInfo] = useState()
	const [amountvalue, setAmountvalue] = useState(0)
	const [autoBettingTimes, setAutoBettingTimes] = useState(10)

	const [initialState, setinitalState] = useState({
		num: 108,
		value: MIN_BET_VALUE
	})
	const [pins, setPins] = useState()
	const [ballRadius, setBallRadius] = useState()
	const [gameData, setGameData] = useState()
	const [userData, setUserData] = useState()
	const [showResult, setShowResult] = useState(false)

	const [rows, setRows] = useState(initialState.num % 100)
	const [risk, setRisk] = useState(Math.floor(initialState.num / 100))
	const [row_id, setRowID] = useState('')
	const [risk_id, setRiskID] = useState('')
	const [focusDivMan, setFocusMan] = useState('')
	const [focusDivAuto, setFocusAuto] = useState('')
	const [Modalresult, setModalresult] = useState([]);
	const scrollRef = useRef(null);

	const [autoBetsStates, setAutoBetsStatus] = useState(false)
	const [volumeStatus, setVolumeStatus] = useState(
		!localStorage.getItem('gameVolume') ? false : true
	)

	//toggle
	const [modal, setModal] = useState(false)
	const Bettoggle = () => setModal(!modal);

	const [dropdownOpen, setdropdownOpen] = useState(false)
	const toggledrop = () => setdropdownOpen(!dropdownOpen)

	let manualInputRef = useRef(null)
	let autoInputRef = useRef(null)

	const [touchPlay] = useSound(click)
	const [winPlay] = useSound(win)

	const {
		selectedCurrency,
		setWalletBalance,
		updatedBalance,
		setUpdatedBalance,
		setHeaderSlider,
		ballsFalling,
		setBallsFalling,
		gameId,
		Decimal,
		activeGames,
		setAmounValueInfo,
		setSelectedCurrency,
		WalletBalanceData,
		setDecimal
	} = CustomUseContext()

	let toastConfigure = {
		hideProgressBar: true,
		autoClose: 2000,
		position: 'top-center'
	}

	const countref = useRef(0)
	countref.current += 1

	useEffect(() => {
		Aos.init({ duration: 400 })

		if (activeGames?.length) {
			if (activeGames.includes(window.location.pathname)) {
				navigate(window.location.pathname)
			} else {
				navigate('/notfound')
			}
		}
	}, [activeGames])

	let currentUrlParams = new URLSearchParams(window.location.search);
	let game_id = currentUrlParams.get('game_id');
	let user_id = currentUrlParams.get('user_id');

	useEffect(() => {
		window.scrollTo(0, 0)
		if (user_id) {

			Helper.PostData(backendAPI + "user/WalletBalance_mob", { user_id: user_id }).then((response) => {
				if (response) {
					setWalletBalance([...response]);
					setSelectedCurrency((selectedCur) => ({
						...response.find((item) => item.name === selectedCur.name),
					}));
				}
			})

			Helper.PostData(backendAPI + "user/getUserdetails_mob", { user_id: user_id }).then((response) => {
				if (response?.status) {
					setUserData(response?.data?.details);
				}
			})

		}

		if (game_id) {
			const Data = {
				url: 'getonegames',
				method: 'post',
				reducerName: 'gameOneResponse',
				payload: { _id: game_id }
			}
			props.commonApiCall(Data)

			const data11 = {
				type: 'user',
				game_id: game_id
			}
			const Data1 = {
				url: 'game_settings/get_risk',
				method: 'post',
				reducerName: 'getrisk',
				payload: data11
			}
			props.commonApiCall(Data1)
			const Data12 = {
				url: 'game_settings/get_betcount',
				method: 'post',
				reducerName: 'getbetcount',
				payload: data11
			}
			props.commonApiCall(Data12)

			const Data123 = {
				url: 'game_settings/get_ballcount',
				method: 'post',
				reducerName: 'getballcount',
				payload: data11
			}
			props.commonApiCall(Data123)
		}
	}, [gameId])

	useEffect(() => {
		if (props.gameOneResponse?.status) {
			let game_data = props.gameOneResponse?.data[0]
			setGameData(game_data)
			setAmountvalue(game_data.min_bet)
		}
	}, [props.gameOneResponse])


	useEffect(() => {
		if (props.getprofile) {
			setUserData(props.getprofile.data.details)
		}

	}, [props.getprofile])

	const [backsound, setbacksound] = useState(false);
	const playBackground = (status) => {
		setbacksound(status);
		if (status) audio.play()
		else audio.pause();
	}

	useEffect(() => {
		if (selectedCurrency && ballsFalling === 0) {
			setUpdatedBalance(selectedCurrency.amount)
			updatedBalanceforbackend = selectedCurrency.amount
		}
	}, [selectedCurrency])

	useEffect(() => {
		if (updatedBalance || updatedBalance === 0) {
			updatedCurrencyBalance = updatedBalance
			selectedCurrency.amount = updatedBalance
			setWalletBalance(walletbalance => [
				...walletbalance.filter(item => item.name !== selectedCurrency.name),
				selectedCurrency
			])
		}
	}, [updatedBalance])

	useEffect(() => {
		setAmounValueInfo(amountvalue)
	}, [amountvalue])

	useEffect(() => {
		setRows(initialState.num % 100)
		setRisk(Math.floor(initialState.num / 100))
		callPin()
	}, [initialState])

	useEffect(() => {
		const subscription = intervalService
			.getMessage()
			.subscribe(({ component }) => {
				if (component === 'clear') {
					stopAutoBet()
				}
			})

		return () => subscription.unsubscribe()
	}, [])

	const callPin = () => {
		let rows = initialState.num % 100
		setBallRadius((0.02 * 16) / rows)

		createPins(rows, rows == 16 ? 'white' : 'white')
	}

	useEffect(() => {
		if (contextRef?.current) {
			const ctx = contextRef.current.getContext('2d')
			setCanvasInfo(ctx)
		}
	}, [contextRef])

	useEffect(() => {
		if (canvasInfo) {
			renderToCanvas()
		}
	}, [canvasInfo, pins])

	useEffect(() => {
		if (rows && props.getballcount) {
			setRowID(props.getballcount?.data?.find(item => item.count == rows).count)
		}
	}, [rows, props.getballcount])

	useEffect(() => {
		if (risk && props.getrisk) {
			setRiskID(
				props.getrisk.data.find((item, index) => index + 1 === risk).level
			)
		}
	}, [risk, props.getrisk])

	const renderToCanvas = () => {
		const width = canvasInfo.canvas.width
		const height = canvasInfo.canvas.height
		canvasInfo.clearRect(0, 0, width, height)
		canvasInfo.save()
		canvasInfo.scale(width / 2, height / 2)
		canvasInfo.translate(1.0, 1.0)
		for (const pin of pins) {
			DrawPin(canvasInfo, pin)
		}
		activeBalls.forEach(x => DrawBall(canvasInfo, x.ball))
		canvasInfo.restore()
	}

	const setAmount = val => {
		if (val) {
			let value = +val
			if (gameData) {
				let { max_bet, min_bet } = gameData
				if (max_bet >= value && min_bet <= value) {
					return true
				} else {
					if (max_bet < value) {
						setAmountvalue(max_bet)
						return false
					} else if (min_bet > value) {
						return true
					}
				}
			}
		} else {

			return true
		}
	}

	const setAmountCalc = (val, type) => {
		let value = +val
		if (gameData) {
			let { max_bet, min_bet } = gameData
			if (max_bet >= value && min_bet <= value) {
				setAmountvalue(value)
				setFocus(type)
			} else {
				if (max_bet < value) {
					setAmountvalue(max_bet)
					setFocus(type)
				} else if (min_bet > value) {
					setAmountvalue(min_bet)
					setFocus(type)
				}
			}
		}
	}

	const setFocus = type => {
		type === 'auto' ? setFocusAuto('') : setFocusMan('')
	}

	const createPins = (rows, color) => {
		const pinRadius = 0.02
		const allpins = []
		// Create pins
		const distance = (2 - 2 * pinRadius) / (rows + 1)
		const rowDistance = Math.sqrt(1.25 * distance * distance)
		const topOffset = 2 - rowDistance * (rows - 1) - pinRadius
		for (let i = 2; i < rows + 2; i++) {
			const startPosX = (-distance * i) / 2
			const posY = (i - 2) * rowDistance - 1 + topOffset
			for (let j = 0; j < i + 1; j++) {
				const posX = startPosX + j * distance
				allpins.push(
					Pin(new Vector(posX, posY), pinRadius, color, '', 0, 0, 0, 0)
				)
			}
		}
		setPins(allpins)
	}

	const DrawPin = (canvasInfo, pin) => {
		canvasInfo.beginPath()
		canvasInfo.arc(pin.position.x, pin.position.y, pin.radius, 0, Math.PI * 2)
		canvasInfo.fillStyle = pin.color
		canvasInfo.shadowColor = pin.shadowColor
		canvasInfo.shadowBlur = pin.shadowBlur
		canvasInfo.shadowOffsetX = pin.shadowOffsetX
		canvasInfo.shadowOffsetY = pin.shadowOffsetY
		canvasInfo.fill()
	}

	/////////////////Ball Section ////////////////////////////

	const BtnFocusOut = target => target.blur()

	const checkBalance = () =>
		new Promise(resolve => {
			if (selectedCurrency) {
				if (+amountvalue <= updatedCurrencyBalance) {
					resolve('ok')
				} else resolve('Insufficient balance')
			} else resolve("Can't get balance, Please try again")
		})

	const getRandom = () => {
		let way = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 0, 10, 11, 12, 13, 14, 15]

		let wayRandom = Random(way)

		let randomArr = randomObj[String(rows)]

		let randNum = Random(randomArr)

		let high =
			rows == 8
				? [0, 1, 7, 8]
				: rows == 12
					? [0, 1, 2, 10, 11, 12]
					: [0, 1, 2, 3, 13, 14, 15, 16]

		let highRan = Random(high)

		randNum = randNum == 0 ? highRan : randNum

		return { randNum, wayRandom }
	}

	const autoBet = async target => {
		window.scrollTo(0, 0)
		BtnFocusOut(target)
		if (+amountvalue && amountvalue >= gameData.min_bet) {
			let Enough = await checkBalance()
			if (Enough === 'ok') {
				setAutoBetsStatus(true)
				let amount = +amountvalue
				autoBetInterval = setInterval(() => {
					if (completedAutoBets == autoBettingTimes) stopAutoBet()
					else {
						completedAutoBets += 1
						placePlinkoBet(true, amount)
					}
				}, 500)
			} else toast.error(Enough, toastConfigure)
		} else {
			autoInputRef.focus()
			setFocusAuto(' focus')
		}
	}

	const ManualBet = async (target, rand) => {

		window.scrollTo(0, 0)
		BtnFocusOut(target)
		if (+amountvalue && amountvalue >= gameData.min_bet)
			placePlinkoBet('', '', rand)
		else {
			manualInputRef.focus()
			setFocusMan(' focus')
		}
	}

	const placePlinkoBet = async (auto, amount, randd) => {
		let Enough = await checkBalance()
		setModal(false)
		setModalresult([])

		if (Enough === 'ok') {
			let amountValue = +(amount ? amount : amountvalue)

			setUpdatedBalance(preBalance => preBalance - amountValue)

			updatedBalanceforbackend -= amountValue

			let { randNum, wayRandom } = getRandom()

			placeBet(
				randNum,
				rows,
				risk,
				amountValue,
				updatedBalanceforbackend,
				gameData._id,
				user_id,
				selectedCurrency.cid,
				row_id,
				risk_id,
				userData.username,
				Decimal,

				async (result, inData) => {
					socket.emit('game_hist', inData)
					updatedBalanceforbackend = result.backendBalance
					setBallsFalling(ballsFalling => ballsFalling + 1)
					startPosition = await addBall(randNum, wayRandom)
					intdata = inData
					results = result
					intdata.insdata.payout_position = getExactPosition(
						startPosition,
						rows,
						results.resultCol
					)

					setModalresult(Modalresult => {
						if (auto)
							return [...Modalresult.slice(-Number(autoBettingTimes)), intdata.insdata]
						return [intdata.insdata]
					})
					setHeaderSlider(headerSlider => {
						if (headerSlider?.length === 5)
							return [...headerSlider.slice(1, 5), intdata.insdata]
						return [...headerSlider, intdata.insdata]
					})

					setUpdatedBalance(
						preBalance => preBalance + result.userProfit * amountvalue
					)

					socket.emit('global_emit', {
						profile: userData.profile_picture,
						username: userData.username,
						cid: selectedCurrency.cid,
						profit_prediction: result.won,
						profit: result.profit
					})

					volume && winPlay()
					setShowResult(true)
					setBallsFalling(ballsFalling => ballsFalling - 1)
					await sleep(200)
					setShowResult(false)

					if (activeBalls?.length === 0) {
						setShowResult(false)
						renderToCanvas()
					}
					!auto && setModal(true)
				}
			)
		} else {
			auto && stopAutoBet()
			toast.error(Enough, toastConfigure)
		}
	}

	const stopAutoBet = () => {
		completedAutoBets = 0
		setAutoBetsStatus(false)
		clearInterval(autoBetInterval)
		setModal(true)
	}

	const addBall = (targetPos, wayRandom) => {
		startPos = startPositions[rows][targetPos][wayRandom]
		startPos = [startPos, -startPos]
		startPos = Random(startPos)

		const promise = new Promise(resolve => {
			activeBalls.push({
				ball: Ball(new Vector(startPos, -1.1), ballRadius, '#58c320'),
				targetPos,
				startPos,
				finishedCallBack: resolve
			})
		})

		if (!animationActive) {
			animationActive = true
			accumulator = 0
			currentTime = Date.now()
			requestAnimationFrame(() => animation(startPos))
		}
		return promise
	}

	const DrawBall = (ctx, ball) => {
		ctx.beginPath()
		ctx.arc(ball.position.x, ball.position.y, ball.radius, 0, Math.PI * 2)
		ctx.fillStyle = ball.color
		ctx.shadowColor = 'green'
		ctx.shadowBlur = 20
		ctx.shadowOffsetX = 5
		ctx.shadowOffsetY = 5
		ctx.fill()
	}

	const animation = startPos => {
		const newTime = Date.now()
		const frameTime = newTime - currentTime
		currentTime = newTime
		accumulator += frameTime * speed
		const dt = 1000 / stepsPerSecond
		while (accumulator >= dt) {
			for (const { ball } of activeBalls) {
				animateBall(ball, 1 / stepsPerSecond, startPos)
			}
			accumulator -= dt
		}
		const finishedBalls = activeBalls.filter(
			ballData => ballData.ball.position.y >= 1
		)
		activeBalls = activeBalls.filter(ballData => ballData.ball.position.y < 1)
		renderToCanvas()
		if (activeBalls?.length > 0) {
			requestAnimationFrame(() => animation(startPos))
		}
		for (const ballData of finishedBalls) {
			ballData.finishedCallBack(ballData.startPos)
		}
		if (activeBalls?.length === 0) {
			animationActive = false
		}
	}

	const animateBall = (ball, dt, startPos) => {
		const initialPos = ball.position
		const initialV = ball.v
		const newV = initialV.add(ballAcceleration.multiply(dt))
		const newPos = initialPos.add(newV.multiply(dt))
		const collisionInfo = getCollisionPin(
			initialPos,
			newPos,
			ball.radius,
			0.02,
			startPos
		)
		if (collisionInfo === undefined) {
			ball.position = newPos
			ball.v = newV
			return
		}
		const { collisionPoint, normal } = collisionInfo
		const aM = ballAcceleration.magnitude()
		const vM = initialV.magnitude()
		const sM = collisionPoint.subtract(initialPos).magnitude()
		const collisionTime =
			(-vM + Math.sqrt(initialV.sqrMagnitude() + 4 * aM * sM)) / (2 * aM)
		const tLeft = dt - collisionTime
		const vCollision = ball.v.add(ballAcceleration.multiply(collisionTime))

		const tmp = normal.multiply(vCollision.dot(normal) * 2)
		const reflectedV = vCollision.subtract(tmp)
		const finalV = reflectedV.multiply(k)
		ball.v = finalV.add(ballAcceleration.multiply(tLeft))
		ball.position = collisionPoint.add(ball.v.multiply(tLeft))
	}

	const getCollisionPin = (start, end, radius, pinRadius, startPos) => {
		// TODO: simple not optimized implementation;
		const maxDistanceSqr = (pinRadius + radius) * (pinRadius + radius)
		const direction = end.subtract(start)
		const magnitudeSqr = direction.sqrMagnitude()
		for (const pin of pins) {
			// calculate nearest point
			const t = Math.max(
				0,
				direction.dot(pin.position.subtract(start)) / magnitudeSqr
			)
			const nearestPoint = start.add(direction.multiply(Math.min(1, t)))
			const projection = start.add(direction.multiply(t))
			const distanceSqr = nearestPoint.subtract(pin.position).sqrMagnitude()
			// calculate distance on nearest point
			const distanceNearestPointSqr = projection
				.subtract(pin.position)
				.sqrMagnitude()
			if (distanceSqr > maxDistanceSqr) {
				continue
			}
			// calculate collision point
			const distFromNearestToCollisionPoint = Math.sqrt(
				maxDistanceSqr - distanceNearestPointSqr
			)
			const collisionPointDist =
				start.subtract(projection).magnitude() - distFromNearestToCollisionPoint
			const collisionPoint = start.add(
				direction.normalize().multiply(collisionPointDist)
			)

			const normal = collisionPoint.subtract(pin.position).normalize()

			if (volume) {
				pin.soundCount < 5 && touchPlay()
				pin.soundCount += 1
			}


			return { collisionPoint, normal }
		}
		return undefined
	}

	const onRowsChange = row => {
		const newNum = Math.floor(initialState.num / 100) * 100 + row
		changeNum(newNum)
	}

	const onRiskChange = value => {
		const newNum = value * 100 + (initialState.num % 100)
		changeNum(newNum)
	}

	const changeNum = num => {
		setinitalState(initialState => ({ ...initialState, num }))
	}

	const ValueChange = () => {
		setModal(false);
		scrollRef.current?.scrollIntoView({ behavior: 'smooth' })
	}

	return (
		<>
			<PageLoader load={props?.loading} />
			<div className='pilnko_section_outer_wrap'>
				<div style={{ zIndex: '1' }}>
					<div className='pilnko_game_outer_wrap Mobwrapper'>
						<img src={hpgamelight1} className='img-fluid hpgamelight1' />
						<img src={hpgamelight2} className='img-fluid hpgamelight2' />
						<img src={plkophyctr} className='img-fluid plkophyctr' />
						<div className='pp__wrapper'>
							<div className={`pp__plinkoGrid ${rows === 16 && 'mt-4'}`}>

								<button type='button' className='CstmBtnOne'>
									{backsound ? (
										<FontAwesomeIcon
											icon={faVolumeHigh}
											className='cursor-pointer'
											onClick={() => playBackground(false)}
										/>
									) : (
										<FontAwesomeIcon
											icon={faVolumeMute}
											className='cursor-pointer'
											onClick={() => playBackground(true)}
										/>
									)}
								</button>

								<button type='button' className='CstmBtn'>
									{volumeStatus ? (
										<FontAwesomeIcon
											icon={faVolumeHigh}
											className='cursor-pointer'
											onClick={() => {
												setVolumeStatus(false)
												volume = false
												localStorage.setItem('gameVolume', '')
											}}
										/>
									) : (
										<FontAwesomeIcon
											icon={faVolumeMute}
											className='cursor-pointer'
											onClick={() => {
												setVolumeStatus(true)
												volume = true
												localStorage.setItem('gameVolume', 'true')
											}}
										/>
									)}
								</button>

								<div style={{ width: '100%', height: '100%' }}>
									<div className='text-center'>
										<img src={hpgamelogo} width={150} />
									</div>
									<canvas
										style={{
											width: '500px',
											height: '500px',
											cursor: 'inherit',
											touchAction: 'auto'
										}}
										height={500}
										width={500}
										ref={contextRef}
									/>
								</div>
								<PayoutTable
									payout={PLINKO_PAYOUT[risk][rows]}
									showResult={showResult}
									resultColumnData={results.resultCol}
									startPos={startPosition}
									rows={rows}
								/>
							</div>
						</div>
					</div>
				</div>

				<div className='pilnko_tabs_outer_wrap' ref={scrollRef}>
					<Nav tabs>
						<NavItem>
							<NavLink
								disabled={ballsFalling > 0}
								className={{ active: activeTab === '1' }}
								onClick={() => toggle('1')}
							>
								{t('Manual')}
							</NavLink>
						</NavItem>
						<NavItem>
							<NavLink
								className={{ active: activeTab === '2' }}
								onClick={() => toggle('2')}
								disabled={ballsFalling > 0}
							>
								{t('Auto')}
							</NavLink>
						</NavItem>
					</Nav>

					<div className='row Mobopns justify-content-center align-items-center'>

						<div className='col-4'>
							<div className='form-group'>
								{props.getrisk?.data?.map(({ level }, index) => (
									<div className='mb-2'>
										<button type="button" className="btn GrdBtn w-100"
											disabled={ballsFalling > 0 || risk === index + 1}
											onClick={({ target: { value } }) => onRiskChange(Number(value))}
											key={index}
											value={index + 1}
										>
											{level}
										</button>
									</div>
								))}
							</div>
						</div>
						<div className='col-4'>
							<TabContent activeTab={activeTab}>
								<TabPane tabId="1">
									<button
										type='button'
										className='btn common_btn_bet'
										disabled={ballsFalling > 0}
										onClick={({ target }) => ManualBet(target, rand)}
									>

										{t("Bet")}
									</button>
								</TabPane>
								<TabPane tabId="2">
									{autoBetsStates ? (
										<button
											type='button'
											className='common_btn_bet'
											onClick={({ target }) => {
												stopAutoBet(target)
											}}
										>
											{t('Stop')}
										</button>
									) : (
										<button
											type='button'
											className='common_btn_bet'
											onClick={({ target }) => autoBet(target)}
										>
											{t("Bet")}
										</button>
									)}
								</TabPane>
							</TabContent>
						</div>

						<div className='col-4'>
							<div className='form-group'>
								{props.getballcount?.data?.map((item, index) => {
									item.count = +item.count
									return (
										<div className='mb-2'>
											<button type="button" className="btn GrdBtn w-100"
												disabled={ballsFalling > 0 || item.count == rows}
												onClick={({ target: { value } }) => onRowsChange(Number(value))}
												key={index}
												value={item.count}
											>
												{item.count}
											</button>
										</div>
									)
								})}
							</div>
						</div>
					</div>

					<div className="pilnko_amount_outer_wrap mb-2">
						<label>{t("Select Betting Asset")}</label>
						<div className="">
							<div className="slct_cion betAsstMnu">

								<UncontrolledDropdown>
									<Dropdown isOpen={dropdownOpen} toggle={toggledrop}>
										<DropdownToggle>
											{selectedCurrency?.name ?
												<div className='slctBet'>
													<div className='d-flex align-items-center'>
														<img
															src={selectedCurrency?.image}
															className="prflImg"
															alt="coin"
														/>
														{selectedCurrency.name}
													</div>
													<span className="truncate">
														{updatedBalance || updatedBalance === 0
															? updatedBalance.toFixed(4)
															: selectedCurrency?.amount?.toFixed(4)}
													</span>
												</div>
												:
												WalletBalanceData?.length > 0 ?
													<div className='slctBet'>
														<div className='d-flex align-items-center'>
															<img
																src={WalletBalanceData[0]?.image}
																className="prflImg"
																alt="coin"
															/>
															{WalletBalanceData[0].name}
														</div>
														<span className="truncate">
															{WalletBalanceData[0]?.amount?.toFixed(4)}
														</span>
													</div>
													:
													<ButtonLoader />
											}
										</DropdownToggle>
										<DropdownMenu className="coinMnu">
											{WalletBalanceData?.length > 0 ?
												WalletBalanceData.map((item, index) => (
													<DropdownItem onClick={() => {
														setSelectedCurrency({ ...item, image: item?.image, });
														setDecimal(item?.decimal);
													}}>
														<div className="coin_img_wrap">
															<div className='d-flex align-items-center'>
																<img src={item?.image} alt="token" className='mx-2' />
																{item?.name}
															</div>
															<span>{item?.amount?.toFixed(4)}</span>
														</div>
													</DropdownItem>
												))
												:
												<DropdownItem>
													<p className="my-2">{t("No Assets found")}</p>
												</DropdownItem>
											}

										</DropdownMenu>
									</Dropdown>
								</UncontrolledDropdown>
							</div>
						</div>

						<div className="minmax row mt-2">
							<div className='col-6'>
								<label className='form-label'>{t("Minimum Bet")}</label>
								<h6>{gameData?.min_bet || "---"}</h6>
							</div>
							<div className='col-6'>
								<label className='form-label'>{t("Maximum Bet")}</label>
								<h6>{gameData?.max_bet || "---"}</h6>
							</div>
						</div>
					</div>

					<TabContent activeTab={activeTab}>
						<TabPane tabId="1">
							<div className={`pilnko_manual_section_wrap`}>
								<div className='pilnko_amount_outer_wrap'>
									<label>{t("Amount")}</label>
									<div className={`amount_input_group_wrap` + focusDivMan}>
										<div className='amount_input_group_btn_wrap'>
											<button
												onClick={() => setAmountCalc(amountvalue * 2, 'man')}
												type='button'
											>
												x2
											</button>
											<button
												onClick={() => setAmountCalc(amountvalue / 2, 'man')}
												type='button'
											>
												/2
											</button>
										</div>
										<div className='amount_input_group_field_wrap'>
											<NumericFormat
												onChange={({ target: { value } }) => {
													setAmountvalue(value)
													if (gameData?.min_bet <= value) setFocusMan('')
												}}
												isAllowed={values => {
													const { floatValue } = values
													return setAmount(floatValue)
												}}
												getInputRef={inputRef => (manualInputRef = inputRef)}
												placeholder='0.00'
												allowNegative={false}
												value={amountvalue}
											/>
											<img
												src={selectedCurrency?.image || ethIcon}
												alt='SPUDS'
											/>
										</div>
									</div>
									<div className=''>
										<ReactSlider
											step={1}
											min={gameData?.min_bet || 0}
											max={gameData?.max_bet || 0}
											tabIndex={100}
											className='matic_slider_outer_wrap cursor-pointer'
											thumbClassName='matic_slider_thumb_wrap'
											value={amountvalue}
											onChange={amountvalue => {

												if (amountvalue <= gameData?.max_bet) setAmountvalue(amountvalue)
												else setAmountvalue(gameData?.max_bet)

												if (gameData?.min_bet <= amountvalue) setFocusMan('')
											}}
										/>
										<div className='matic_min_max_wrap'>
											<span>{t('Minimum')}</span>
											<span>{t('Maximum')}</span>
										</div>
									</div>
								</div>
							</div>
						</TabPane>
						<TabPane tabId="2">
							<div className={`pilnko_manual_section_wrap`}>
								<div className='pilnko_amount_outer_wrap'>
									<label>{t('Amount')}</label>
									<div className={`amount_input_group_wrap` + focusDivAuto}>
										<div className='amount_input_group_btn_wrap'>
											<button
												onClick={() => setAmountCalc(amountvalue * 2, 'auto')}
												type='button'
											>
												x2
											</button>
											<button
												onClick={() => setAmountCalc(amountvalue / 2, 'auto')}
												type='button'
											>
												/2
											</button>
										</div>
										<div className='amount_input_group_field_wrap'>
											<NumericFormat
												isAllowed={values => {
													const { floatValue } = values
													return setAmount(floatValue)
												}}
												onChange={({ target: { value } }) => {
													setAmountvalue(value)
													if (gameData?.min_bet <= value) setFocusAuto('')
												}}
												placeholder='0.00'
												getInputRef={inputRef => (autoInputRef = inputRef)}
												allowNegative={false}
												value={amountvalue}
											/>
											<img
												src={selectedCurrency?.image || ethIcon}
												alt='SPUDS'
											/>
										</div>
									</div>
									<div className='matic_amount_slider_wrap'>
										<ReactSlider
											step={1}
											min={gameData?.min_bet || 0}
											max={gameData?.max_bet || 0}
											tabIndex={100}
											className='matic_slider_outer_wrap cursor-pointer'
											thumbClassName='matic_slider_thumb_wrap'
											value={amountvalue}
											onChange={amountvalue => {
												setAmountvalue(amountvalue)
												if (gameData?.min_bet <= amountvalue) setFocusAuto('')
											}}
										/>
										<div className='matic_min_max_wrap'>
											<span>{t('Minimum')}</span>
											<span>{t('Maximum')}</span>
										</div>
									</div>
									<label>{t('Number Of Bets')}</label>
									<div className='amount_input_group_wrap'>
										<div className='amount_input_group_btn_wrap'>
											{props.getbetcount?.data?.map(({ count }, index) => (
												<>
													<button
														key={index}
														onClick={() => setAutoBettingTimes(count)}
														type='button'
													>
														{count == Infinity ?
															<img src={infinite_icon} alt='infinite'></img>
															: count}
													</button>

												</>
											))}
										</div>
										<div className='amount_input_group_field_wrap'>
											<NumericFormat
												onChange={({ target: { value } }) => setAutoBettingTimes(value)}
												placeholder={autoBettingTimes == Infinity ? '∞' : '0'}
												allowNegative={false}
												value={autoBettingTimes}
											/>
										</div>
									</div>

								</div>
							</div>
						</TabPane>
					</TabContent>
				</div>
			</div>

			<Modal
				isOpen={modal}
				toggle={Bettoggle}
				backdrop={false}
				centered
				className="signin_model_wrap model_common_style_wrap"
			>
				<ModalBody>
					<div className="close_model" onClick={Bettoggle}>
						x
					</div>
					<div className="model_bg_wrap">
						<img src={hpgamelogo} alt="hplogo" width={100} />
					</div>
					<div className="bet_slip_outer_wrap text-center">
						<p>{t("Betting Results")}</p>
						{Modalresult?.length > 0 &&
							Modalresult.map((item, index) => (
								<button key={index} className={`cstmMdlBtn ${item.profit_prediction && "winner"} mb-2`} data-aos="zoom-in">
									{item.payout}x
								</button>
							))}

						<p>{t("betagain")}?</p>
						<div className='d-flex justify-content-between'>
							<TabContent activeTab={activeTab}>
								<TabPane tabId='1'>
									<div className='text-center'>
										<button
											type='button'
											className='common_btn_purple_wrap mx-2'
											onClick={({ target }) => ManualBet(target, rand)}
										>

											{t("Betagain")}
										</button>
									</div>
								</TabPane>
								<TabPane tabId='2'>
									<button
										type='button'
										className='common_btn_purple_wrap mx-2'
										onClick={({ target }) => autoBet(target)}
									>
										{t("Betagain")}
									</button>
								</TabPane>
							</TabContent>
							<button
								type='button'
								className='common_btn_purple_wrap mx-2'
								onClick={ValueChange}
							>
								{t("ChangeVal")}
							</button>
						</div>
					</div>
				</ModalBody>
			</Modal>
		</>
	)
}

const mapStateToProps = state => ({
	gameOneResponse: state.gameOneResponse,
	getUserdetails: state.getUserdetails,
	getprofile: state.getprofile,
	getrisk: state.getrisk,
	getbetcount: state.getbetcount,
	getballcount: state.getballcount,
	loading: state.loading
})

const mapDispatchToProps = dispatch => ({
	commonApiCall: data => dispatch({ type: 'commonApiCall', data })
})

export default connect(mapStateToProps, mapDispatchToProps)(Plinko)
