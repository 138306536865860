
export const startPositions = {
    8: {
        0: [
            0.0806,
            0.0861,
            0.0254,
            0.0189,
            0.0806,
            0.1606,
            0.1071,
            0.0884,
            0.0341,
            0.0962,
            0.0806,
            0.0793,
            0.0793,
            0.1178,
            0.0021,
            0.1178,
        ],
        1: [
            0.031,
            0.0381,
            0.162,
            0.0808,
            0.0808,
            0.031,
            0.0384,
            0.091,
            0.0382,
            0.1079,
            0.1079,
            0.0248,
            0.0139,
            0.0906,
            0.0014,
            0.0169,
        ],
        2: [
            0.0724,
            0.0724,
            0.1784,
            0.0503,
            0.1177,
            0.176,
            0.101,
            0.1528,
            0.1784,
            0.0176,
            0.0503,
            0.0323,
            0.1528,
            0.101,
            0.0422,
            0.0908,
        ],
        3: [
            0.1147,
            0.0153,
            0.1152,
            0.1147,
            0.0153,
            0.1546,
            0.1899,
            0.0334,
            0.194,
            0.0107,
            0.0291,
            0.0877,
            0.1473,
            0.0664,
            0.0877,
            0.1899,
        ],
        4: [
            0.0552,
            0.0174,
            0.0552,
            0.022,
            0.0174,
            0.0979,
            0.022,
            0.1776,
            0.1531,
            0.055,
            0.0493,
            0.055,
            0.1752,
            0.0119,
            0.1609,
            0.0119,
        ],
        5: [
            0.1792,
            0.0994,
            0.1167,
            0.004,
            0.004,
            0.004,
            0.1935,
            0.0383,
            0.0043,
            0.1977,
            0.0826,
            0.1125,
            0.1125,
            0.0462,
            0.0231,
            0.0492,
        ],
        6: [
            0.0196,
            0.1852,
            0.1852,
            0.1719,
            0.042,
            0.0259,
            0.0215,
            0.0215,
            0.0578,
            0.1926,
            0.068,
            0.0196,
            0.1704,
            0.042,
            0.0196,
            0.042,
        ],
        7: [
            0.1731,
            0.1731,
            0.1731,
            0.1731,
            0.1731,
            0.1592,
            0.1592,
            0.1949,
            0.0135,
            0.043,
            0.058,
            0.059,
            0.1809,
            0.043,
            0.1875,
            0.1875,
        ],
        8: [
            0.1136,
            0.1136,
            0.1136,
            0.0703,
            0.0703,
            0.0703,
            0.0047,
            0.1958,
            0.1,
            0.0691,
            0.1425,
            0.0282,
            0.1914,
            0.1414,
            0.0703,
            0.1793,
        ],
    },
    12: {
        0: [
            0.057,
            0.057,
            0.0789,
            0.0828,
            0.057,
            0.0828,
            0.0738,
            0.057,
            0.1144,
            0.0698,
            0.1144,
            0.0617,
            0.0477,
            0.0827,
            0.057,
            0.1302,
        ],
        1: [
            0.0465,
            0.0465,
            0.0465,
            0.0465,
            0.1163,
            0.1078,
            0.0221,
            0.0042,
            0.0239,
            0.0465,
            0.063,
            0.1228,
            0.1228,
            0.063,
            0.0221,
            0.0832,
        ],
        2: [
            0.1442,
            0.0381,
            0.0588,
            0.0619,
            0.0588,
            0.0381,
            0.0393,
            0.0311,
            0.0575,
            0.1442,
            0.0142,
            0.038,
            0.0575,
            0.0575,
            0.1465,
            0.0636,
        ],
        3: [
            0.0196,
            0.0196,
            0.0363,
            0.1422,
            0.1136,
            0.0364,
            0.0364,
            0.0364,
            0.1136,
            0.0384,
            0.0363,
            0.0363,
            0.0364,
            0.0364,
            0.0589,
            0.1447,
        ],
        4: [
            0.0025,
            0.1167,
            0.1206,
            0.0664,
            0.1382,
            0.0047,
            0.0047,
            0.1059,
            0.1051,
            0.1167,
            0.1167,
            0.005,
            0.1059,
            0.1406,
            0.1318,
            0.1167,
        ],
        5: [
            0.0153,
            0.0153,
            0.0153,
            0.1177,
            0.0153,
            0.0806,
            0.1177,
            0.1177,
            0.1177,
            0.0334,
            0.1177,
            0.1177,
            0.1177,
            0.0153,
            0.0153,
            0.0334,
        ],
        6: [
            0.031,
            0.1235,
            0.0243,
            0.043,
            0.031,
            0.1235,
            0.0507,
            0.0507,
            0.1235,
            0.031,
            0.1233,
            0.1232,
            0.043,
            0.043,
            0.0507,
            0.1217,
        ],
        7: [
            0.1379,
            0.1379,
            0.1152,
            0.0748,
            0.1401,
            0.0223,
            0.1379,
            0.0826,
            0.1152,
            0.0671,
            0.1401,
            0.1379,
            0.0092,
            0.0092,
            0.0092,
            0.1152,
        ],
        8: [
            0.021,
            0.021,
            0.041,
            0.1125,
            0.1125,
            0.041,
            0.0652,
            0.0652,
            0.1461,
            0.0275,
            0.1461,
            0.0275,
            0.0125,
            0.0275,
            0.1178,
            0.1178,
        ],
        9: [
            0.0513,
            0.0513,
            0.0513,
            0.1294,
            0.1294,
            0.0043,
            0.0043,
            0.1294,
            0.0106,
            0.0106,
            0.0513,
            0.0043,
            0.0513,
            0.0513,
            0.0513,
            0.0513,
        ],
        10: [
            0.1419,
            0.0322,
            0.1291,
            0.0291,
            0.0231,
            0.1419,
            0.0034,
            0.0034,
            0.0427,
            0.1199,
            0.0231,
            0.0231,
            0.0231,
            0.0305,
            0.0322,
            0.1199,
        ],
        11: [
            0.1429,
            0.1429,
            0.0215,
            0.1429,
            0.1427,
            0.1427,
            0.077,
            0.0265,
            0.0265,
            0.077,
            0.1295,
            0.0265,
            0.1295,
            0.0787,
            0.0397,
            0.0265,
        ],
        12: [
            0.0079,
            0.0711,
            0.1309,
            0.1175,
            0.108,
            0.1192,
            0.0079,
            0.0792,
            0.0711,
            0.108,
            0.108,
            0.0711,
            0.0444,
            0.1309,
            0.1192,
            0.0792,
        ],
    },
    16: { 
        0: [0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447, 0.0447],
        1: [
            0.0846,
            0.0489,
            0.0489,
            0.0846,
            0.0489,
            0.0489,
            0.0846,
            0.0489,
            0.0846,
            0.0846,
            0.0846,
            0.0489,
            0.0846,
            0.0489,
            0.0846,
            0.0489,
        ],
        2: [
            0.0529,
            0.0238,
            0.0291,
            0.0291,
            0.0439,
            0.0238,
            0.0291,
            0.0291,
            0.0797,
            0.0439,
            0.0529,
            0.0529,
            0.0439,
            0.0439,
            0.0439,
            0.0529,
        ],
        3: [
            0.0293,
            0.0564,
            0.0564,
            0.0293,
            0.0042,
            0.0293,
            0.0564,
            0.0564,
            0.0564,
            0.0042,
            0.0526,
            0.0564,
            0.0564,
            0.0526,
            0.0526,
            0.0526,
        ],
        4: [
            0.0259,
            0.0259,
            0.0259,
            0.0259,
            0.0259,
            0.0118,
            0.0118,
            0.0118,
            0.0118,
            0.0118,
            0.0118,
            0.0259,
            0.0259,
            0.0259,
            0.0259,
            0.0259,
        ],
        5: [
            0.0174,
            0.0174,
            0.0174,
            0.0174,
            0.0174,
            0.0174,
            0.0132,
            0.0174,
            0.0132,
            0.0132,
            0.0132,
            0.0132,
            0.0132,
            0.0132,
            0.0132,
            0.0132,
        ],
        6: [
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
            0.0034,
        ],
        7: [
            0.0381,
            0.0381,
            0.0381,
            0.0135,
            0.0135,
            0.0381,
            0.0528,
            0.0135,
            0.0381,
            0.0381,
            0.0135,
            0.0135,
            0.0135,
            0.0135,
            0.0381,
            0.0381,
        ],

        8: [
            0.0552,
            0.1071,
            0.091,
            0.1071,
            0.1071,
            0.091,
            0.091,
            0.091,
            0.0552,
            0.0552,
            0.0552,
            0.1071,
            0.1071,
            0.1071,
            0.1071,
            0.1071,
        ],

        9: [
            0.0296,
            0.0296,
            0.0296,
            0.1125,
            0.0296,
            0.0296,
            0.0296,
            0.1125,
            0.0296,
            0.0296,
            0.0296,
            0.0296,
            0.0296,
            0.0296,
            0.0296,
            0.0296,
        ],

        10: [
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
            0.1079,
        ],



        11: [
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
            0.0905,
        ],



        12: [
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
            0.077,
        ],


        13: [
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
            0.0363,
        ],


        14: [
            0.1121,
            0.0652,
            0.0652,
            0.0431,
            0.0431,
            0.053,
            0.0431,
            0.0431,
            0.0445,
            0.053,
            0.0431,
            0.0445,
            0.0445,
            0.0817,
            0.0652,
            0.1121,
        ],



        15: [
            0.0043,
            0.0043,
            0.0513,
            0.0513,
            0.0043,
            0.0513,
            0.0513,
            0.0043,
            0.0319,
            0.0319,
            0.0319,
            0.0319,
            0.0043,
            0.0513,
            0.0513,
            0.0513,
        ],
        16: [
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
            0.0245,
        ],
    },
};
