export const EnglishKey = [
    { key: "Required", message: "Please fill required fields" },
    { key: "Ipblock", message: "Your IP is Blocked" },
    { key: "Notfoundadmin", message: "Unable to find Admin" },
    { key: "Verifytfa", message: "Please verify TFA to login" },
    { key: "Loginsuccess", message: "Login successfull" },
    { key: "invalidlogin", message: "Invalid login credential" },
    { key: "error", message: "Something went wrong" },
    { key: "blocked", message: "Blocked...Too many failed login attempts" },
    { key: "resetlink", message: "Password reset link is sent to your email" },
    { key: "Mailnotfound", message: "Email Not Found" },
    { key: "Invalidpass", message: "Invalid confirm password" },
    { key: "Resetlinkinvalid", message: "Reset password link is not valid" },
    { key: "Resetsuccess", message: "Password reset successfully" },
    { key: "Invalidpatt", message: "Invalid confirm pattern" },
    { key: "Resetpattinvalid", message: "Reset pattern link is not valid" },
    { key: "Resetpattsuccess", message: "Pattern reset successfully" },
    { key: "resetpattlink", message: "Pattern reset link is sent to your email" },
    { key: "Passwordupdate", message: "Password Updated Successfully" },
    { key: "Passwordupdatemismatch", message: "New Password and Confirm New Password Mismatched" },
    { key: "Invalidoldpass", message: "Incorrect old password" },
    { key: "Successpattern", message: "Pattern Updated successfully" },
    { key: "Pattmismatch", message: "New Pattern and Confirm Pattern should be same" },
    { key: "Invalidoldpatt", message: "Old Pattern is Incorrect" },
    { key: "Templatenotfound", message: "Email Template Not Found" },
    { key: "updatetemp", message: "Email Template Updated Successfully" },
    { key: "Verifytfasucc", message: "Two Factor Authentication is Successfully Verified" },
    { key: "Verifytfaerr", message: "Invalid Two Factor Authentication Code" },
    { key: "Notfoundadmin", message: "Admin Not Found" },
    { key: "Tfadisable", message: "TFA Disabled Successfully" },
    { key: "Tfaenable", message: "TFA Enabled successfully" },
    { key: "Notfoundcms", message: "No cms found" },
    { key: "Nochanges", message: "No Changes occurs" },
    { key: "Cmsupdate", message: "CMS updated Successfully" },
    { key: "Mobileupdate", message: "Mobile Content updated successfully" },
    { key: "Versionupdate", message: "Version updated successfully" },
    { key: "Quesexist", message: "Question already Exists" },
    { key: "Addfaq", message: " Faq data is saved" },
    { key: "Faqupdate", message: "FAQ Data Updated Successfully" },
    { key: "FaqStatus", message: "FAQ status updated successfully" },
    { key: "Faqmust", message: "Faq Must Have Atleast One Data" },
    { key: "Deletedata", message: "Data deleted Successfully" },
    { key: "Categoryexist", message: "Category already Exists" },
    { key: "Categorysave", message: "Category saved" },
    { key: "Invalidmail", message: "Register user mail not allowed" },
    { key: "Mailexist", message: "Register Email Already Exist" },
    { key: "Userregister", message: "Registration Successfully Completed. Kindly check your mail to activate your account " },
    { key: "UsernameExist", message: "Username already taken by another user" },
    { key: "InvalidOTP", message: "Incorrect OTP" },
    { key: "ExpiredOTP", message: "OTP has been expired" },
    { key: "Verified", message: "Your account has been confirmed. Please proceed to login" },
    { key: "Verifieduser", message: "User already verified" },
    { key: "Otpexpired2", message: "OTP Expired Kindly Check Your Email to New OTP" },
    { key: "Invalidlogin", message: "Invalid login credentia" },
    { key: "Verifynotify", message: "Your account verification is not yet complete,Kindly check your mail to activate your account" },
    { key: "Verifymessage", message: "User Please Verify your Account" },
    { key: "Forgotpass", message: "Forgot Password Request Sent successfully. Kindly Check Your Email to Further Process" },
    { key: "Imageformat", message: "Please select jpg,jpeg,png format only" },
    { key: "Profileupdate", message: "Profile updated successfully" },
    { key: "Samepasserr", message: "Old Password and New Password Not be Same" },
    { key: "Contactus", message: "Query submitted to admin Successfully" },
    { key: "Depsiterr", message: "Deposit error" },
    { key: "Depsitsuccess", message: "Deposit Successfull" },
    { key: "withdrawsuccess", message: "Withdraw Completed Successfully" },
    { key: "Withdrawerr", message: "Withdraw Request Failed" },
    { key: "Insuffbal", message: "Insufficient Wallet Balance" },
    { key: "Invalidcurr", message: "Invalid currency Request" },
    { key: "Norecord", message: "No record found" },
    { key: "success", message: "success" },
    { key: "auth", message: "A token is required for authentication" },
    { key: "ServerError", message: "Internal Server Error!" }
]





































































