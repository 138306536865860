import React, { useState, useEffect } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import user_profile from "../assets/images/user_profile.png";
import protection_shield from "../assets/images/protection_shield.png";
import edit_icon from "../assets/images/edit_icon.png";
import trophy from "../assets/images/trophy.png";
import error_icon from "../assets/images/error_icon.png";
import { Link, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";

import Security from "./Security";
import { PageLoader } from "./shared/loader";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { BounceInDownButton } from "../animate/bouncein";
import Helper from "../Helper/Helper";

const format = ["image/jpg", "image/png", "image/jpeg"];

const Profile = ({
  getprofile,
  commonApiCall,
  getstatistics,
  linkRes,
}) => {

  const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onChange" });
  const { state } = useLocation();
  const { t } = useTranslation();

  const [getprofiledata, setGetprofiledata] = useState(null);
  const [profieupload, setProfileupload] = useState(true);
  const [img, setImg] = useState();
  const [uploadimg, setUploadimg] = useState();
  const [loading, setLoading] = useState(true);

  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  const onImageChange = async (e) => {
    const file = e.target.files[0];
    if (!format.includes(file.type)) {
      toast.error("JPG ,PNG , JPEG format only allowed");
      return
    }
    else {
      let isFileValid = await Helper?.isValidFile(e, file, 'img');
      if (isFileValid) {
        setUploadimg(e.target.files[0]);
        const reader = new FileReader();
        reader.addEventListener("load", () => {
          setImg(reader.result);
        });
        reader.readAsDataURL(e.target.files[0]);
      }
    }

    // var size = file.size;
    // if (size > "1000000") {
    //   toast.error("Please select image size less than 1MB");
    //   return;
    // } else {
    // }
  };


  useEffect(() => {
    document.title = `HouseofPlinko | ${("Profile Settings")}`;
    if (getprofile) {
      if (getprofile?.status) {
        setLoading(false);

        Object.assign(getprofile?.data?.details, {
          secretky: getprofile?.data?.secretky
        })

        setGetprofiledata(getprofile.data?.details);
      }
    } else getUserProfileData();
  }, [linkRes, getprofile]);

  const getUserProfileData = () => {
    const Data = {
      url: "user/getUserdetails",
      method: "post",
      reducerName: "getprofile",
    };
    commonApiCall(Data, (response) => {
      if (response) {
        setLoading(false)
        Object.assign(getprofile?.data?.details, {
          secretky: getprofile?.data?.secretky
        })
        setGetprofiledata(response.data?.details);
      }
    });
  }

  useEffect(() => {
    if (state?.active === "security") setActiveTab("2");
  }, [state]);

  useEffect(() => {
    window.scrollTo(0, 0)
    const Data = {
      url: "user/getstatistics",
      method: "post",
      reducerName: "getstatistics",
    };
    commonApiCall(Data);
  }, []);

  const onSubmit = (data) => {
    var formData = new FormData();
    setLoading(true);

    if (data.username == getprofiledata?.username) {
      formData.append("profile_picture", uploadimg);

      const Data1 = {
        url: "user/saveProfile",
        method: "post",
        reducerName: "profileupdate",
        payload: formData,
        type: "formdata"
      };
      commonApiCall(Data1, (response) => {
        if (response) {
          setProfileupload(true);
          getUserProfileData();
          reset();
        }
      });
      setProfileupload(true);
    } else {
      formData.append("username", data.username);
      formData.append("profile_picture", uploadimg);

      const Data = {
        url: "user/saveProfile",
        method: "post",
        reducerName: "profileupdate",
        payload: formData,
        type: "formdata"
      };
      commonApiCall(Data, (response) => {
        if (response) {
          reset();
          setProfileupload(true);
          getUserProfileData();
        }
      });
      setProfileupload(true);
    }
  };

  return (
    <>
      <PageLoader load={loading} />
      <div className="profile_section_outer_wrap px-4">
        <h3 className="page_title_wrap">{t("Profile")}</h3>
        <div className="pilnko_tabs_outer_wrap minHght">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  toggle("1");
                }}
              >
                <img src={user_profile} alt="user_profile" /> {t("Profile")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  toggle("2");
                }}
              >
                <img src={protection_shield} alt="protection_shield" />
                {t("security")}
              </NavLink>
            </NavItem>
          </Nav>

          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <div className="profile_content_outer_wrap wallet_content_outer_wrap">
                {profieupload ? (
                  getprofiledata ? (
                    <div className="wallet_content_inner_wrap animated_div">
                      <BounceInDownButton>
                        <div className="profile_avator_wrap">
                          <div className="profile_avator_img_wrap">
                            <img src={getprofiledata?.profile_picture} alt="profile_icon" />
                          </div>
                          <div className="profile_name_id">
                            <h4>{getprofiledata?.username}</h4>
                            <h5>{t("User Id")}: {getprofiledata?.uid}</h5>
                            <div className="Prfmail">
                              <h5>{t("E-mail")}: {getprofile?.data?.email}</h5>
                            </div>
                          </div>

                          <button type="button" onClick={() => setProfileupload(false)}>
                            <img src={edit_icon} alt="edit_icon" />
                          </button>
                        </div>
                      </BounceInDownButton>
                      {getstatistics && getstatistics.data ? (
                        <div className="profile_statistics_outer_wrap">
                          <h4>{t("Statistics")}</h4>
                          <div className="profile_statistics_info_wrap">
                            <div className="profile_statistics_info_inner_wrap">
                              <img src={trophy} alt="" />
                              <h5>{t("Number of Wins")}</h5>
                              <h6>{getstatistics?.data[0]?.wins}</h6>
                            </div>
                            <div className="profile_statistics_info_inner_wrap">
                              <img src={error_icon} alt="" />
                              <h5>{t("Number of Loss")}</h5>
                              <h6>{getstatistics?.data[0]?.loss}</h6>
                            </div>
                            <Link to="/CR1wmDruLQkWc">{t("See All")}</Link>
                          </div>
                        </div>
                      ) : (
                        <div className="profile_statistics_outer_wrap">
                          <h4>{t("Statistics")}</h4>
                          <div className="profile_statistics_info_wrap">
                            <div className="profile_statistics_info_inner_wrap">
                              <img src={trophy} alt="" />
                              <h5>{t("Number of Wins")}</h5>
                              <h6>0</h6>
                            </div>
                            <div className="profile_statistics_info_inner_wrap">
                              <img src={error_icon} alt="" />
                              <h5>{t("Number of Loss")}</h5>
                              <h6>0</h6>
                            </div>
                            <Link to="/CR1wmDruLQkWc">{t("See All")}</Link>
                          </div>
                        </div>
                      )}
                    </div>
                  ) : null
                ) : (
                  <>
                    <span type="button" onClick={() => setProfileupload(true)}>
                      <FontAwesomeIcon icon={faAngleDoubleLeft} /> Back
                    </span>
                    <div className="profile_update_content_outer_wrap wallet_content_inner_wrap animated_div">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="profile_avator_wrap">
                          <div className="profile_avator_img_wrap">
                            <img
                              src={img ? img : getprofiledata?.profile_picture}
                              alt="profile_icon"
                            />
                            <div className="profile_img_upload">
                              <label htmlFor="profile_up">
                                <img src={edit_icon} alt="edit_icon" />
                              </label>
                              <input
                                type="file"
                                id="profile_up"
                                name="profileImg"
                                onChange={onImageChange}
                              />
                            </div>
                          </div>
                          <div className="profile_name_id">
                            <div className="form-group">
                              <label>{t("username")}</label>
                              <input
                                type="text"
                                placeholder={t("Fieldusername")}
                                name="username"
                                defaultValue={getprofiledata.username}
                                {...register("username", {
                                  required: t("userreq"),
                                  minLength: {
                                    value: 3,
                                    message: t("minlen"),
                                  },
                                  pattern: {
                                    value: /^([a-zA-Z0-9 _-]+)$/,
                                    message: t("userpat"),
                                  },
                                })}
                              />
                              {errors.username && (
                                <p className="text-danger">* {errors.username.message}</p>
                              )}
                            </div>
                            {/* <span>Accepted file types (.jpg, .jpeg, .png)</span>
                            <p>Note : Your profile picture must be less than 1MB.</p> */}
                            <button
                              type="submit"
                              className="profile_modify_btn"
                              disabled={loading}

                            >
                              {loading ? "Loading..." : t("Modify")}
                            </button>
                          </div>
                        </div>
                      </form>
                    </div>
                  </>
                )}
              </div>
            </TabPane>
            <TabPane tabId="2">
              <Security userData={getprofiledata} getUserProfileData={getUserProfileData} />
            </TabPane>
          </TabContent>
        </div>
      </div >
    </>
  );
};


const mapStateToProps = (state) => ({
  getprofile: state.getprofile,
  profileupdate: state.profileupdate,
  loading: state.loading,
  getstatistics: state.getstatistics,
  linkRes: state.linkRes,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) =>
    dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Profile);
