const loading = false;

const reducer = (state = { loading }, action) => {
  switch (action.type) {
    case "SET_LOADING":
      return { ...state, loading: true };

    case "RESPONSE":
      return {
        ...state,
        [action.reducerName]: action.responseData,
        loading: false,
      };
    case "CLEAR_STATE":
      return {
        ...state,
        [action.reducerName]: undefined,
        loading: false,
      };

    default:
      return state;
  }
};

export default reducer;
