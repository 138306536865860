import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { DropdownItem, DropdownMenu, DropdownToggle, NavLink, UncontrolledDropdown } from "reactstrap";

export const MultiLang = ({ sideMenu }) => {

    const { i18n } = useTranslation();
    const [selected, setselected] = useState({
        language: "English",
        key: "en",
        icon: "🇦🇨"
    });

    useEffect(() => {
        if (localStorage.getItem("hop-lang")) {
            let body = document.querySelector("html")
            body.setAttribute("lang", localStorage.getItem("hop-lang"));
            let useLang = Languages.find((id) => id.key == localStorage.getItem("hop-lang"))
            if (useLang) setselected(useLang);
        }
    }, [])

    const Languages = [
        {
            language: "English",
            key: "en",
            icon: "🇦🇨"
        },
        {
            language: "French",
            key: "fr",
            icon: "🇫🇷"
        },
        {
            language: "German",
            key: "ge",
            icon: "🇩🇪"
        },
        {
            language: "Italien",
            key: "it",
            icon: "🇮🇹"
        },
        {
            language: "Spanish",
            key: "es",
            icon: "🇪🇸"
        },
    ]

    const changeLang = (item) => {
        setselected(item);
        sideMenu();
        i18n.changeLanguage(item.key);
        localStorage.setItem("hop-lang", item.key);

        let body = document.querySelector("html")
        body.setAttribute("lang", item.key);
    }

    return (
        <div className="Mltlang">
            <UncontrolledDropdown>
                <DropdownToggle>
                    <NavLink>
                        <span className="hdMuIc">
                            {selected.icon}
                        </span>
                        <span className="hdMuTx">{selected.language}</span>
                    </NavLink>
                </DropdownToggle>
                <DropdownMenu className="coinMnu">
                    {Languages.map((list, index) => (
                        <DropdownItem onClick={() => changeLang(list)} key={index}>
                            {list.icon} {list.language}
                        </DropdownItem>
                    ))}
                </DropdownMenu>
            </UncontrolledDropdown>
        </div>
    )
}