import React, { useEffect, useRef, useState } from "react";
import game_img from "../../assets/images/game_img.png";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PageLoader } from "../shared/loader";

function About({ commonApiCall }) {

  const [showContent, setShowContent] = useState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title=`HouseofPlinko | ${t("About Us")}`;

    const Data1 = {
      url: "getcms",
      method: "get",
      reducerName: "getAboutus",
    };
    commonApiCall(Data1, (response) => {
      if (response) {
        setShowContent(response.data[4]?.content);
        setLoading(false);
      }
    });
  }, []);


  return (
    <><PageLoader load={loading} />
      <div className="minHght">
        <div className="cms_section_outer_wrap">
          <h3 className="page_title_wrap">{t("About Us")}</h3>
          <div className="row">
            <div className="col-lg-8">
              <div className="cms_content_inner_wrap about_us_content">
                <p dangerouslySetInnerHTML={{ __html: showContent }}></p>

              </div>
            </div>
            <div className="col-lg-4">
              <div className="cms_game_img">
                <img src={game_img} alt="game_img" />
              </div>
            </div>

          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) => dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(null, mapDispatchToProps)(About);
