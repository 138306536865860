import React, { useState, useEffect } from "react";
import {
  TabContent,
  TabPane,
  Nav,
  NavItem,
  NavLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
} from "reactstrap";
import piggy_bank from "../assets/images/piggy_bank.png";
import withdraw from "../assets/images/withdraw.png";
import { useTranslation } from "react-i18next";
import { Link, useParams, useNavigate, useLocation } from "react-router-dom";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { erc20ABI, useAccount, useNetwork, useSwitchNetwork } from "wagmi";

import ethIcon from '../assets/images/eth.png';
import bnbIcon from "../assets/images/binancebadge.png";
import { CustomUseContext } from "../context/ContextProvider";
import { ContractLoader, PageLoader } from "./shared/loader";
import Helper from "../Helper/Helper";
import { useConnectWalletModalActions } from "./zus/zustan";

import bnbjson from '../contract/bnb.json';
import Ethjson from '../contract/eth.json';
import { waitForTransaction } from '@wagmi/core'
import { toast } from "react-toastify";
import { depositetoken, getSigner, readcontract, useGetNativeCoinBalance, withdrawToken } from "../hooks/useToken";
import { allowancefn } from "./web3method";
import { useTokenContract } from "../hooks/useContract";
import { ColorRing } from "react-loader-spinner";
import Aos from 'aos';
import { BounceInDownButton } from "../animate/bouncein";
import hplogo from "../assets/images/hp-logo.png";

import { Modal, ModalBody } from "reactstrap";
import { NumericFormat } from "react-number-format";


const backendAPI = Helper.backendAPI();

function Wallet({
  commonApiCall,
  walletbalance,
  getprofile,
  withdrawwalletResponse,
  depositaddress,
  depositmetamaskResponse,
  depositwalletResponse,
  clearstate,
}) {

  const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onTouched" });
  const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, reset: reset1 } = useForm({ mode: "onTouched" });
  const { address, isConnected } = useAccount();
  const { toggleConnectWalletModal } = useConnectWalletModalActions();
  const { chain } = useNetwork();
  const { switchNetworkAsync, isLoading } = useSwitchNetwork();

  let location = useLocation();
  const tab = useParams();

  const { t } = useTranslation();
  const { setloaderMessage } = CustomUseContext();
  const navigate = useNavigate();
  const kycdata = getprofile?.data?.details;

  // useStates
  const [tokenList, settokenList] = useState([]);
  const [selectnetwork, setSelectnetwork] = useState("Select Network");
  const [selected_token, setselected_token] = useState({});
  const [selected_withdraw, setselected_withdraw] = useState({});
  const [loading, setLoading] = useState(false);

  // Dropdowns
  const [dropdownOpen, setdropdownOpen] = useState(false)
  const toggledrop = () => setdropdownOpen(!dropdownOpen)
  const [dropdownOpen1, setdropdownOpen1] = useState(false)
  const toggledrop1 = () => setdropdownOpen1(!dropdownOpen1)
  const [dropdownOpen2, setdropdownOpen2] = useState(false)
  const toggledrop2 = () => setdropdownOpen2(!dropdownOpen2)
  const [dropdownOpen3, setdropdownOpen3] = useState(false)
  const toggledrop3 = () => setdropdownOpen3(!dropdownOpen3)


  const [otpVerify, setOtpVerifymodal] = useState(false);
  const [otp, setOtp] = useState("");

  const [withdrawObj, setWithdrawObj] = useState('');

  const verifyOtptoggle = () => {
    setOtpVerifymodal(!otpVerify);
  };



  const [activeTab, setActiveTab] = useState("1");
  const toggle = (tab) => setActiveTab(tab);

  useEffect(() => {
    setSelectnetwork("Select Network")
    setselected_token({})
    setselected_withdraw({})
  }, [address, chain])

  useEffect(() => {
    if (depositmetamaskResponse) {
      if (depositmetamaskResponse?.status) {
        clearstate({
          reducerName: "depositmetamaskResponse",
        });
      }
    }
    if (depositwalletResponse) {
      if (depositwalletResponse?.status) {
        clearstate({
          reducerName: "depositwalletResponse",
        });
      }
    }
  }, [depositmetamaskResponse, depositwalletResponse]);

  useEffect(() => {
    document.title = "HouseofPlinko | Deposit-withdraw";
    const Data = {
      url: "user/WalletBalance",
      method: "get",
      reducerName: "walletbalance",
    };
    commonApiCall(Data)
  }, [depositmetamaskResponse, withdrawwalletResponse, depositwalletResponse]);

  useEffect(() => {
    window.scrollTo(0, 0)
    Aos.init({ duration: 200 })

    const Data123 = {
      url: "user/getUserdetails",
      method: "post",
      reducerName: "getprofile",
    };
    commonApiCall(Data123);

    if (chain?.id == 1 || chain?.id == 56) {
      const Data12 = {
        url: "user/get_currency_detail",
        method: "post",
        reducerName: "currency_details",
        payload: { "network": chain?.id == 1 ? "Etherscan" : chain?.id == 56 ? "Bscscan" : null }
      };

      commonApiCall(Data12, (data) => {
        if (data) {
          settokenList(data?.data);
        }
      });
    }
  }, [address, chain]);


  useEffect(() => {

    if (tab?.tab == "1") {
      setActiveTab("1")
    } else if (tab?.tab == "2") {
      setActiveTab("2")
    }
  }, [depositaddress, location]);

  const tokenContract = useTokenContract(selected_token?.contract_address)
  const ApproveFunc = async (contractAddress, coinmath) => {

    try {
      const hash = await tokenContract.write.approve([contractAddress, coinmath.toString()], { account: address, value: 0 })
      const receipt = await waitForTransaction({ hash })
      return true;
    } catch (err) {
      console.log(err);
      setLoading(false);
      return false
    }
  }

  const depositmetamask = async (data) => {

    let coinmath = Math.pow(10, selected_token?.decimal) * Number(data.deposit_amount);

    let ContractAddress;
    let ContractAbi;

    if (chain?.id == 1) {
      ContractAddress = Ethjson.contractAddress;
      ContractAbi = Ethjson.abi;
    } else if (chain?.id == 56) {
      ContractAddress = bnbjson.contractAddress;
      ContractAbi = bnbjson.abi;
    } else {
      toast.error((t("Change network")))
      return
    }

    if (selected_token?.type === "1") {
      if (tknWalletBlce < Number(data.deposit_amount)) {
        toast.error(t("Insufficient balance"))
        return
      }

      setLoading(true);
      setloaderMessage("Confirm deposit")

      let hash = await depositetoken(ContractAbi, ContractAddress, ["0x0000000000000000000000000000000000000000", 0], address, String(coinmath))
      if (!hash) {
        setLoading(false);
        return
      }


      const DepositData = {
        amount: Number(data.deposit_amount),
        from_address: address,
        to_address: ContractAddress,
        hash: hash?.hash,
        type: "metamask",
        currencySymbol: selected_token?.currency_symbol,
        network: selectnetwork
      };
      const Data = {
        url: "user/user_deposit",
        method: "post",
        reducerName: "depositmetamaskResponse",
        payload: DepositData,
      };
      commonApiCall(Data);
      reset();
      setLoading(false);

    } else {
      if (tknWalletBlce < Number(data.deposit_amount)) {
        toast.error(t("Insufficient balance"))
        return
      }

      if (selected_token?.contract_address) {
        setLoading(true);
        setloaderMessage("Checking allowance")

        await allowancefn(selected_token?.contract_address, address).then(async (allowance) => {
          if (!allowance) {
            setLoading(false);
            return
          }
          if (Number(allowance) < coinmath) {
            setloaderMessage("Approve allowance")

            let Approve = await ApproveFunc(ContractAddress, coinmath)
            if (!Approve) return
            setloaderMessage("Confirm deposit")

            let hash = await depositetoken(ContractAbi, ContractAddress, [selected_token?.contract_address, coinmath.toString()], address, 0)
            if (!hash) {
              setLoading(false);
              return
            }

            const DepositData = {
              amount: Number(data.deposit_amount),
              from_address: address,
              to_address: ContractAddress,
              hash: hash?.hash,
              network: selectnetwork,
              type: "metamask",
              currencySymbol: selected_token?.currency_symbol,
              network: selectnetwork
            };
            const Data = {
              url: "user/user_deposit",
              method: "post",
              reducerName: "depositmetamaskResponse",
              payload: DepositData,
            };
            commonApiCall(Data);
            reset();
            setLoading(false);
          } else {
            setloaderMessage("Confirm deposit")

            let hash = await depositetoken(ContractAbi, ContractAddress, [selected_token?.contract_address, coinmath.toString()], address, 0)
            if (!hash) {
              setLoading(false);
              return
            }
            const DepositData = {
              amount: Number(data.deposit_amount),
              from_address: address,
              to_address: ContractAddress,
              hash: hash?.hash,
              network: selectnetwork,
              type: "metamask",
              currencySymbol: selected_token?.currency_symbol,
              network: selectnetwork
            };
            const Data = {
              url: "user/user_deposit",
              method: "post",
              reducerName: "depositmetamaskResponse",
              payload: DepositData,
            };
            commonApiCall(Data);
            reset();
            setLoading(false);
          }
        })
      } else toast.error(t("SelectAsset"))
    }
  };


  const withdrawsubmit = async (data) => {
    let amountwei = Number(data.withdraw_amount) * Math.pow(10, selected_withdraw?.decimal);
    let ContractAddress;
    let ContractAbi;
    let token_address;

    if (chain?.id == 1) {
      ContractAddress = Ethjson.contractAddress;
      ContractAbi = Ethjson.abi;
    } else if (chain?.id == 56) {
      ContractAddress = bnbjson.contractAddress;
      ContractAbi = bnbjson.abi;
    } else {
      toast.error((t("Change network")))
      return
    }

    const verify = {
      _id: getprofile?.data?.details?._id,
      tfa_code: data.tfa_code,
      type: "withdraw"
    };

    if (selected_withdraw?.type === '1') token_address = "0x0000000000000000000000000000000000000000"
    else if (selected_withdraw?.type === '0' && selected_withdraw?.contract_address) token_address = selected_withdraw?.contract_address;
    else {
      toast.info(t("Asset type invalid"))
      return
    }

    setLoading(true);
    setloaderMessage("Verifying TFA");
    let tfaStatus = await Helper.PostData(backendAPI + "user/verify_tfa", verify).then((response) => {
      return response?.status
    })
    if (!tfaStatus) {
      setLoading(false);
      return
    }

    setloaderMessage("Getting signer");

    let hash = await getSigner(ContractAbi, ContractAddress, 'SIGNATURE_PERMIT_TYPEHASH')
    if (!hash) {
      setLoading(false);
      return
    }

    let Signdata = {
      SIGNATURE_PERMIT_TYPEHASH: hash,
      useraddress: address,
      token_address: token_address,
      amount: amountwei,
      contract_address: ContractAddress,
    }

    Helper.PostData(backendAPI + "user/sign", Signdata).then(async (res) => {

      setloaderMessage("Confirm withdraw");

      let txHash = await withdrawToken(
        ContractAbi,
        ContractAddress,
        [token_address, amountwei.toString(), (res?.expiryTime).toString(), res?.sig?.signature],
        address
      )

      if (!txHash) {
        setLoading(false);
        return
      }


      // WITHDRAW OTP




      let withData = {
        amount: Number(data.withdraw_amount),
        from_address: ContractAddress,
        to_address: address,
        token_address: token_address,
        wallet_txid: "txHash?.hash",
        currencyId: selected_withdraw?._id,
        symbol: selected_withdraw?.currency_symbol,
        network: selectnetwork,
        status: true,
      }
      setWithdrawObj(withData);
      verifyOtptoggle();

      // Helper.PostData(backendAPI + "user/user_withdraw", withData).then((response) => {
      //   setLoading(false);
      // })

      setLoading(false);
      // reset1();

    })

  };


  const verifyOTPWithdraw = async (e) => {
    e.preventDefault();
    setLoading(true);
    Object.assign(withdrawObj, {
      'otp': otp
    });

    Helper.PostData(backendAPI + "user/user_withdraw", withdrawObj).then((response) => {
      setLoading(false);
      setOtp("");
      verifyOtptoggle();
      reset1();
    })
  }

  const siteBalance = walletbalance?.find((element, index) => {
    return selected_token?.symbol || selected_token?.currency_symbol == element?.name
  })

  const withdrawBalance = walletbalance?.find((element, index) => {
    return selected_withdraw?.symbol || selected_withdraw?.currency_symbol == element?.name
  })

  const changeNetwork = async (id, network) => {

    if (isConnected) {
      setselected_token({});
      settknWalletBlce(null);

      if (chain?.id == id) {
        setSelectnetwork(network)
        return;
      } else {
        let Chnetwork = await switchNetworkAsync?.(id);
        if (Chnetwork) {
          setSelectnetwork(network)
        }
        else return;
      }
    } else toggleConnectWalletModal()
  }

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  const [tknWalletBlce, settknWalletBlce] = useState(null);
  const nativeBalance = useGetNativeCoinBalance(chain?.id);
  const [depField, setdepField] = useState(false);
  const depositToken = async (list) => {
    setdepField(true);
    setselected_token(list)
    if (address) {
      if (list?.type === "0") {
        let weiBalance = await readcontract(erc20ABI, list?.contract_address, 'balanceOf', [address], chain?.id)
        if (!weiBalance) {
          settknWalletBlce(null);
          setdepField(false)
          return
        }
        settknWalletBlce(weiBalance);
      } else {
        settknWalletBlce(nativeBalance?.balance);
      }
    }
    setdepField(false)
  }

  return (
    <div className="row wallet_section_outer_wrap">

      <PageLoader load={isLoading} />
      <ContractLoader load={loading} />

      <div className="col-xl-12 col-lg-12">
        <h3 className="page_title_wrap">{t("Wallet")}</h3>
        <div className="pilnko_tabs_outer_wrap">
          <Nav tabs>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "1" }}
                onClick={() => {
                  toggle("1");
                }}
              >
                <img src={piggy_bank} alt="piggy bank" /> {t("Deposit")}
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={{ active: activeTab === "2" }}
                onClick={() => {
                  toggle("2");
                }}
              >
                <img src={withdraw} alt="piggy bank" /> {t("Withdraw")}
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent activeTab={activeTab}>
            <TabPane tabId="1">
              <BounceInDownButton>
                <form onSubmit={handleSubmit(depositmetamask)} autoComplete="off">
                  <div className="wallet_content_outer_wrap animated_div">
                    <div className="wallet_content_inner_wrap">


                      <div className="wallet_currency_outer_wrap">

                        <div className="pilnko_amount_outer_wrap">
                          <label>{t("Select Network")}</label>
                          <div className="amount_input_group_wrap">
                            <div className="amount_input_group_btn_wrap slct_cion coinMnuDv">

                              <UncontrolledDropdown>
                                <Dropdown isOpen={dropdownOpen2} toggle={toggledrop2}>
                                  <DropdownToggle caret>
                                    {selectnetwork !== "Select Network" ?
                                      <img src={chain?.id == 56 ? bnbIcon : ethIcon} width={18} /> : null}
                                    {t(selectnetwork)}
                                  </DropdownToggle>
                                  <DropdownMenu className="coinMnu">
                                    <DropdownItem onClick={() => changeNetwork(1, "Etherscan")}>
                                      <img src={ethIcon} width={18} /> Etherscan
                                    </DropdownItem>
                                    <DropdownItem onClick={() => changeNetwork(56, "Bscscan")}>
                                      <img src={bnbIcon} width={18} /> Bscscan
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>

                        <div className="pilnko_amount_outer_wrap">
                          <label>{t("Deposit Currency")}</label>
                          <div className="amount_input_group_wrap">
                            <div className="amount_input_group_btn_wrap slct_cion coinMnuDv">

                              <UncontrolledDropdown>
                                <Dropdown isOpen={dropdownOpen} toggle={toggledrop}>
                                  <DropdownToggle caret>
                                    <img src={selected_token?.image} width={18} />
                                    {selected_token?.currency_symbol ? selected_token?.currency_symbol : t("Select Asset")}
                                  </DropdownToggle>
                                  <DropdownMenu className="coinMnu">
                                    {tokenList?.length > 0 ?
                                      tokenList?.map((list, index) => (
                                        list?.status ?
                                          <DropdownItem onClick={() => depositToken(list)} key={index}>
                                            <img src={list.image} width={18} /> {list.currency_symbol}
                                          </DropdownItem> : null
                                      ))
                                      :
                                      <DropdownItem>
                                        {t("No Assets found")}
                                      </DropdownItem>
                                    }
                                  </DropdownMenu>
                                </Dropdown>
                              </UncontrolledDropdown>
                            </div>

                            <div className="amount_input_group_field_wrap">
                              <div className="d-flex">
                                <h6>{t("Balance")}</h6>
                                {depField ?
                                  <span className='d-flex justify-content-center' style={{ width: "150px" }}>
                                    <ColorRing
                                      visible={true}
                                      height="25"
                                      width="25"
                                      ariaLabel="blocks-loading"
                                      wrapperClass="blocks-wrapper"
                                      colors={['#fff', '#fff', '#fff', '#fff', '#fff']}
                                    />
                                  </span>
                                  :
                                  <input
                                    type="text"
                                    placeholder="0.000000"
                                    readOnly
                                    value={tknWalletBlce || "0.000000"}
                                  />
                                }
                              </div>

                              <div className="d-flex">
                                <h6>{t("Site Balance")}</h6>
                                <input
                                  type="text"
                                  placeholder="0.000000"
                                  readOnly
                                  value={siteBalance?.amount?.toFixed(6) || "0.000000"}
                                />
                              </div>

                            </div>
                          </div>
                        </div>
                        <div className="pilnko_amount_outer_wrap deposit_address_input">
                          <label>{t("Deposit Amount")}</label>
                          <div className="amount_input_group_wrap">
                            <div className="amount_input_group_field_wrap copy_input">

                              <input type="text"
                                className="px-2 from-control"
                                placeholder={t("Enter Deposit Amount")}
                                name="deposit_amount"
                                {...register("deposit_amount", {
                                  required: t("Deposit amount is requried"),
                                  pattern: {
                                    value: /^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/,
                                    message: t("Invalid amount")
                                  },
                                })}
                              />
                            </div>
                          </div>
                          {errors.deposit_amount &&
                            <p className="text-danger">
                              * {errors.deposit_amount.message}
                            </p>
                          }
                        </div>
                        <div className="wallet_withdraw_confirm text-center">
                          {isConnected ?
                            <button type="submit" className="copy_address">
                              {t("Deposit")}
                            </button>
                            :
                            <button type="button" className="copy_address" onClick={toggleConnectWalletModal}>
                              {t("Connect Wallet")}
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </BounceInDownButton>
            </TabPane>
            <TabPane tabId="2">
              <BounceInDownButton>
                <form onSubmit={handleSubmit1(withdrawsubmit)} autoComplete="off">
                  <div className="wallet_content_outer_wrap animated_div">
                    <div className="wallet_content_inner_wrap">
                      <div className="wallet_currency_outer_wrap">

                        <div className="pilnko_amount_outer_wrap">
                          <label>{t("Select Network")}</label>
                          <div className="amount_input_group_wrap">
                            <div className="amount_input_group_btn_wrap slct_cion coinMnuDv">
                              <UncontrolledDropdown>
                                <Dropdown isOpen={dropdownOpen3} toggle={toggledrop3}>
                                  <DropdownToggle caret>
                                    {selectnetwork !== t("Select Network") ?
                                      <img src={chain?.id == 56 ? bnbIcon : ethIcon} width={18} /> : null}
                                    {t(selectnetwork)}
                                  </DropdownToggle>
                                  <DropdownMenu className="coinMnu">

                                    <DropdownItem onClick={() => changeNetwork(1, "Etherscan")}>
                                      <img src={ethIcon} width={18} /> Etherscan
                                    </DropdownItem>
                                    <DropdownItem onClick={() => changeNetwork(56, "Bscscan")}>
                                      <img src={bnbIcon} width={18} /> Bscscan
                                    </DropdownItem>
                                  </DropdownMenu>
                                </Dropdown>
                              </UncontrolledDropdown>
                            </div>
                          </div>
                        </div>

                        <div className="pilnko_amount_outer_wrap">
                          <label>{t("Withdraw Currency")}</label>
                          <div className="amount_input_group_wrap">
                            <div className="amount_input_group_btn_wrap slct_cion coinMnuDv">

                              <UncontrolledDropdown>
                                <Dropdown isOpen={dropdownOpen1} toggle={toggledrop1}>
                                  <DropdownToggle caret>
                                    <img src={selected_withdraw?.image} width={18} />
                                    {selected_withdraw?.currency_symbol ? selected_withdraw?.currency_symbol : t("Select Asset")}
                                  </DropdownToggle>
                                  <DropdownMenu className="coinMnu">
                                    {tokenList?.length > 0 ?
                                      tokenList?.map((list, index) => (
                                        list?.status ?
                                          <DropdownItem onClick={() => setselected_withdraw(list)} key={index}>
                                            <img src={list.image} width={18} /> {list.currency_symbol}
                                          </DropdownItem> : null
                                      ))
                                      :
                                      <DropdownItem>
                                        {t("No Assets found")}
                                      </DropdownItem>
                                    }
                                  </DropdownMenu>
                                </Dropdown>
                              </UncontrolledDropdown>
                            </div>

                            <div className="amount_input_group_field_wrap">
                              <h6>{t("Balance")}</h6>
                              <input
                                type="text"
                                placeholder="0.000000"
                                readOnly
                                value={withdrawBalance?.amount?.toFixed(6) || "0.0000"}
                              />
                            </div>
                          </div>
                        </div>
                        <div className="pilnko_amount_outer_wrap deposit_address_input">
                          <label>{t("Withdraw Amount")}</label>
                          <div className="amount_input_group_wrap">
                            <div className="amount_input_group_field_wrap copy_input">
                              <input
                                placeholder={t("Enter withdraw amount")}
                                name="withdraw_amount"
                                {...register1("withdraw_amount", {
                                  required: t("Withdraw amount is requried"),
                                  pattern: {
                                    value: /^(?:[1-9]\d*|0(?!(?:\.0+)?$))?(?:\.\d+)?$/,
                                    message: t("Invalid amount")
                                  },
                                })}
                              />
                            </div>
                          </div>
                          {errors1.withdraw_amount && (
                            <p className="text-danger">
                              * {errors1.withdraw_amount.message}
                            </p>
                          )}
                        </div>
                        <div className="pilnko_amount_outer_wrap deposit_address_input">
                          <label>{t("TFA Code")}</label>
                          <div className="amount_input_group_wrap">
                            <div className="amount_input_group_field_wrap copy_input">
                              <input
                                type="number"
                                placeholder={t("Enter Your 2FA Code")}
                                id="code"
                                name="tfa_code"
                                onKeyDown={handleKeyDown}
                                {...register1("tfa_code", {
                                  required: t("Tfareq"),
                                  pattern: {
                                    value: /^(\+\d{1,3}[- ]?)?\d{6}$/,
                                    message: t("Tfapatt"),
                                  },
                                })}
                              />
                            </div>
                          </div>
                          {errors1.tfa_code && (
                            <p className="text-danger"> * {errors1.tfa_code.message}</p>
                          )}
                        </div>

                        <div className="wallet_withdraw_confirm text-center">
                          {isConnected ?
                            kycdata?.randcode !== "disable" ? (
                              <button className="copy_address" type="submit">
                                {t("Confirm")}
                              </button>
                            ) : (
                              <button
                                className="copy_address"
                                type="button"
                                onClick={() =>
                                  navigate("/CRiFhNLxx07vg", {
                                    state: { active: "security" },
                                  })
                                }
                              >
                                {t("Enable TFA")}
                              </button>
                            )
                            :
                            <button className="copy_address" type="button"
                              onClick={toggleConnectWalletModal}>
                              {t("Connect Wallet")}
                            </button>
                          }
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </BounceInDownButton>
            </TabPane>
          </TabContent>
        </div>

        {/* OTP Verify*/}

        <Modal
          isOpen={otpVerify}
          toggle={verifyOtptoggle}
          backdrop={false}
          className="signin_model_wrap model_common_style_wrap"
          centered
        >
          <ModalBody>
            <div className="close_model" onClick={verifyOtptoggle}>
              x
            </div>
            <div className="model_bg_wrap">
              <img src={hplogo} alt="hplogo" width={100} />
            </div>
            {/* <TfaVerify /> */}


            <form
              className="sigin_form_wrap rest_password_wrap"
              autoComplete={"off"}
            >
              <div className="form-group">
                <NumericFormat placeholder="Enter the OTP" className="from-control" maxLength="4" allowLeadingZeros required value={otp} onChange={(e) => setOtp(e.target.value)} />
              </div>

              <div className="signin_submit d-flex justify-content-center">
                <button type="submit" className="signin_btn_wrap" onClick={(e) => verifyOTPWithdraw(e)} disabled={loading}>
                  {loading ? t("Loading") : t("submit")}
                </button>
              </div>
            </form>
          </ModalBody>
        </Modal>

      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  Loading: state.loading,
  walletbalance: state.walletbalance,
  getprofile: state.getprofile,
  withdrawwalletResponse: state.withdrawwalletResponse,
  verify_withdraw: state.verify_withdraw,
  depositaddress: state.depositaddress,
  getonecurrency: state.getonecurrency,
  depositmetamaskResponse: state.depositmetamaskResponse,
  depositwalletResponse: state.depositwalletResponse,

});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) =>
    dispatch({ type: "commonApiCall", data, callback }),
  clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Wallet);
