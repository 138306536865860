export const FrenchKey = [
    { key: "Required", message: "Veuillez remplir les champs requis" },
    { key: "Ipblock", message: "Votre IP est bloquée" },
    { key: "Notfoundadmin", message: "Impossible de trouver l'administrateur" },
    { key: "Verifytfa", message: "Veuillez vérifier TFA pour vous connecter " },
    { key: "Loginsuccess", message: "Connexion réussie " },
    { key: "invalidlogin", message: "Indemnité de connexion non valide " },
    { key: "error", message: "Quelque chose s'est mal passé " },
    { key: "blocked", message: "Bloqué ... trop de tentatives de connexion ratées" },
    { key: "resetlink", message: "Le lien de réinitialisation du mot de passe est envoyé à votre e-mail" },
    { key: "Mailnotfound", message: "Email non trouvé" },
    { key: "Invalidpass", message: "Confirmer le mot de passe non valide" },
    { key: "Resetlinkinvalid", message: "Réinitialiser le lien de mot de passe n'est pas valide" },
    { key: "Resetsuccess", message: "Réinitialiser le mot de passe avec succès" },
    { key: "Invalidpatt", message: "Modèle de confirmation non valide" },
    { key: "Resetpattinvalid", message: "La liaison de motif de réinitialisation n'est pas valide" },
    { key: "Resetpattsuccess", message: "Réinitialisation du modèle" },
    { key: "resetpattlink", message: "Le lien de réinitialisation du modèle est envoyé à votre e-mail" },
    { key: "Passwordupdate", message: "Mot de passe mis à jour avec succès" },
    { key: "Passwordupdatemismatch", message: "Nouveau mot de passe et confirmez un nouvel mot de passe incompatible" },
    { key: "Invalidoldpass", message: "Old Mot de passe incorrect" },
    { key: "Successpattern", message: "Modèle mis à jour avec succès" },
    { key: "Pattmismatch", message: "Le nouveau modèle et le modèle de confirmation devraient être les mêmes" },
    { key: "Invalidoldpatt", message: "Le vieux motif est incorrect" },
    { key: "Templatenotfound", message: "Modèle de messagerie introuvable" },
    { key: "updatetemp", message: "Modèle de messagerie mis à jour avec succès" },
    { key: "Verifytfasucc", message: "L'authentification à deux facteurs est vérifiée avec succès" },
    { key: "Verifytfaerr", message: "Code d'authentification à deux facteurs non valide" },
    { key: "Notfoundadmin", message: "Administrateur non trouvé" },
    { key: "Tfadisable", message: "TFA handicapé avec succès" },
    { key: "Tfaenable", message: "TFA a activé avec succès" },
    { key: "Notfoundcms", message: "Pas de CMS trouvé" },
    { key: "Nochanges", message: "Aucun changement ne se produit" },
    { key: "Cmsupdate", message: "CMS a mis à jour avec succès" },
    { key: "Mobileupdate", message: "Contenu mobile mis à jour avec succès" },
    { key: "Versionupdate", message: "Version mise à jour avec succès" },
    { key: "Quesexist", message: "La question existe déjà" },
    { key: "Addfaq", message: "Les données FAQ sont enregistrées" },
    { key: "Faqupdate", message: "Données FAQ mises à jour avec succès" },
    { key: "FaqStatus", message: "Statut FAQ mis à jour avec succès" },
    { key: "Faqmust", message: "La FAQ doit avoir au moins une donnée" },
    { key: "Deletedata", message: "Données supprimées avec succès" },
    { key: "Categoryexist", message: "La catégorie existe déjà" },
    { key: "Categorysave", message: "Catégorie enregistrée" },
    { key: "Invalidmail", message: "Enregistrez le courrier utilisateur non autorisé " },
    { key: "Mailexist", message: "Enregistrer le courrier électronique existe déjà" },
    { key: "Userregister", message: "Inscription terminée avec succès. Veuillez vérifier votre courrier pour activer votre compte" },
    { key: "UsernameExist", message: "Nom d'utilisateur déjà pris par un autre utilisateur" },
    { key: "InvalidOTP", message: "OTP incorrect" },
    { key: "ExpiredOTP", message: "OTP a été expiré" },
    { key: "Verified", message: "Votre compte a été confirmé. Veuillez vous connecter " },
    { key: "Verifieduser", message: "L'utilisateur déjà vérifié" },
    { key: "Otpexpired2", message: "OTP a expiré de vérifier votre e-mail vers un nouvel OTP" },
    { key: "Invalidlogin", message: "Créditerie de connexion non valide" },
    { key: "Verifynotify", message: "La vérification de votre compte n'est pas encore terminée, veuillez vérifier votre courrier pour activer votre compte" },
    { key: "Verifymessage", message: "Utilisateur Veuillez vérifier votre compte" },
    { key: "Forgotpass", message: "Demande de mot de passe oubliée envoyée avec succès. Veuillez consulter votre e-mail pour un processus supplémentaire" },
    { key: "Imageformat", message: "Veuillez sélectionner JPG, JPEG, PNG Format uniquement" },
    { key: "Profileupdate", message: "Mise à jour du profil réussie" },
    { key: "Samepasserr", message: "Ancien mot de passe et nouveau mot de passe n'est pas le même" },
    { key: "Contactus", message: "Requête soumise à l'administrateur avec succès" },
    { key: "Depsiterr", message: "Erreur de dépôt" },
    { key: "Depsitsuccess", message: "Dépôt de réussite" },
    { key: "withdrawsuccess", message: "Se retirer terminé avec succès" },
    { key: "Withdrawerr", message: "Échec de la demande de retrait" },
    { key: "Insuffbal", message: "Équilibre de portefeuille insuffisant" },
    { key: "Invalidcurr", message: "Demande de devise non valide" },
    { key: "Norecord", message: "Aucun Enregistrement Trouvé" },
    { key: "success", message: "succès" },
    { key: "auth", message: "Un jeton est requis pour l'authentification" },
    { key: "ServerError", message: "Erreur interne du serveur!" }
]












































































































































