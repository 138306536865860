import React, { useEffect, useState, useRef } from "react";
import { connect } from "react-redux";
import { useTranslation, I18nextProvider } from "react-i18next";

import {
  CAccordion,
  CAccordionHeader,
  CAccordionItem,
  CAccordionBody,
} from "@coreui/react";
import { PageLoader } from "../shared/loader";

function Faq({ commonApiCall }) {

  const [getdata, setGetdata] = useState();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    window.scrollTo(0, 0)
    document.title = `HouseofPlinko | ${t("Frequently Asked Questions")}`;

    const data12 = {
      type: "user",
    };
    const Data1 = {
      url: "allfaq",
      method: "post",
      reducerName: "getfaq",
      payload: data12,
    };
    commonApiCall(Data1, (response) => {
      if (response) {
        setGetdata(response.data);
        setLoading(false);
      }
    });
  }, []);

  return (
    <>
      <PageLoader load={loading} />
      <div className="minHght">
        <div className="cms_section_outer_wrap" >
          <h3 className="page_title_wrap">
            {t("Frequently Asked Questions")}
          </h3>

          <div className="row">
            <div className="col-lg-6">
              <div className="faq_accordion_outer_wrap">
                {getdata?.map((element, index) => {
                  return index % 2 == 0 ?
                    <CAccordion>
                      <CAccordionItem itemKey={index}>
                        <CAccordionHeader>{element.question}</CAccordionHeader>
                        <CAccordionBody>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: element.description,
                            }}
                          ></p>
                        </CAccordionBody>
                      </CAccordionItem>
                    </CAccordion>
                    : null
                })}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="faq_accordion_outer_wrap">
                {getdata?.map((element, index) => {
                  return index % 2 !== 0 ?
                    <CAccordion>
                      <CAccordionItem itemKey={index}>
                        <CAccordionHeader>{element.question}</CAccordionHeader>
                        <CAccordionBody>
                          <p
                            dangerouslySetInnerHTML={{
                              __html: element.description,
                            }}
                          ></p>
                        </CAccordionBody>
                      </CAccordionItem>
                    </CAccordion>
                    : null
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) => dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(null, mapDispatchToProps)(Faq);
