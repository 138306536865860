export const GermanKey = [
    { key: "Required", message: "Bitte füllen Sie die erforderlichen Felder aus" },
    { key: "Ipblock", message: "Ihre IP ist blockiert" },
    { key: "Notfoundadmin", message: "Admin kann nicht finden" },
    { key: "Verifytfa", message: "Bitte überprüfen Sie die TFA, um sich anzumelden " },
    { key: "Loginsuccess", message: "Login erfolgreich " },
    { key: "invalidlogin", message: "Ungültige Login -Anmeldeinformationen " },
    { key: "error", message: "Etwas ist schief gelaufen" },
    { key: "blocked", message: "Blockiert ... zu viele fehlgeschlagene Anmeldeversuche" },
    { key: "resetlink", message: "Der Link zum Zurücksetzen des Passworts wird an Ihre E -Mail gesendet" },
    { key: "Mailnotfound", message: "Email wurde nicht gefunden" },
    { key: "Invalidpass", message: "Ungültiges Passwort bestätigen" },
    { key: "Resetlinkinvalid", message: "Das Zurücksetzen des Passwort -Links ist nicht gültig" },
    { key: "Resetsuccess", message: "Passwort erfolgreich zurücksetzen" },
    { key: "Invalidpatt", message: "Ungültiges Bestätigungsmuster" },
    { key: "Resetpattinvalid", message: "Das Reset -Muster -Link ist nicht gültig" },
    { key: "Resetpattsuccess", message: "Muster erfolgreich zurücksetzen" },
    { key: "resetpattlink", message: "Der Muster -Reset -Link wird an Ihre E -Mail gesendet" },
    { key: "Passwordupdate", message: "Passwort erfolgreich aktualisiert" },
    { key: "Passwordupdatemismatch", message: "Neues Passwort und bestätigen Sie das neue Passwort nicht übereinstimmend" },
    { key: "Invalidoldpass", message: "Falsches altes Passwort" },
    { key: "Successpattern", message: "Muster erfolgreich aktualisiert" },
    { key: "Pattmismatch", message: "Neues Muster und Bestätigungsmuster sollten gleich sein" },
    { key: "Invalidoldpatt", message: "Das alte Muster ist falsch" },
    { key: "Templatenotfound", message: "E -Mail -Vorlage nicht gefunden" },
    { key: "updatetemp", message: "E -Mail -Vorlage erfolgreich aktualisiert" },
    { key: "Verifytfasucc", message: "Zwei Faktorauthentifizierung wird erfolgreich überprüft" },
    { key: "Verifytfaerr", message: "Ungültiger Zwei -Faktor -Authentifizierungscode" },
    { key: "Notfoundadmin", message: "Administrator nicht gefunden" },
    { key: "Tfadisable", message: "TFA deaktiviert erfolgreich" },
    { key: "Tfaenable", message: "TFA erfolgreich aktiviert" },
    { key: "Notfoundcms", message: "Kein CMS gefunden" },
    { key: "Nochanges", message: "Es treten keine Änderungen auf" },
    { key: "Cmsupdate", message: "CMS erfolgreich aktualisiert" },
    { key: "Mobileupdate", message: "Mobile Inhalte erfolgreich aktualisiert" },
    { key: "Versionupdate", message: "Version erfolgreich aktualisiert" },
    { key: "Quesexist", message: "Frage existiert bereits" },
    { key: "Addfaq", message: "FAQ -Daten werden gespeichert" },
    { key: "Faqupdate", message: "FAQ -Daten erfolgreich aktualisiert" },
    { key: "FaqStatus", message: "FAQ -Status erfolgreich aktualisiert" },
    { key: "Faqmust", message: "FAQ muss mindestens eine Daten haben" },
    { key: "Deletedata", message: "Daten erfolgreich gelöscht" },
    { key: "Categoryexist", message: "Kategorie existiert bereits" },
    { key: "Categorysave", message: "Kategorie gespeichert" },
    { key: "Invalidmail", message: "Registrieren Sie Benutzerpost nicht erlaubt " },
    { key: "Mailexist", message: "Registrieren Sie E -Mails bereits existieren bereits" },
    { key: "Userregister", message: "Registrierung erfolgreich abgeschlossen. Bitte überprüfen Sie Ihre E -Mail, um Ihr Konto zu aktivieren" },
    { key: "UsernameExist", message: "Benutzername, das bereits von einem anderen Benutzer aufgenommen wurde" },
    { key: "InvalidOTP", message: "Falsch OTP" },
    { key: "ExpiredOTP", message: "OTP wurde abgelaufen" },
    { key: "Verified", message: "Ihr Konto wurde bestätigt. Bitte fahren Sie mit der Anmeldung fort " },
    { key: "Verifieduser", message: "Benutzer bereits überprüft" },
    { key: "Otpexpired2", message: "OTP lief abgelaufen. Bitte überprüfen Sie Ihre E -Mail an New OTP" },
    { key: "Invalidlogin", message: "Ungültige Login -Credentia" },
    { key: "Verifynotify", message: "Ihre Kontoüberprüfung ist noch nicht vollständig. Überprüfen Sie bitte Ihre E -Mail, um Ihr Konto zu aktivieren" },
    { key: "Verifymessage", message: "Benutzer Bitte überprüfen Sie Ihr Konto" },
    { key: "Forgotpass", message: "Passwortanforderung erfolgreich gesendet. Bitte überprüfen Sie Ihre E -Mails, um weiter zu verarbeiten" },
    { key: "Imageformat", message: "Bitte wählen Sie JPG, JPEG, nur PNG -Format aus" },
    { key: "Profileupdate", message: "Profil erfolgreich aktualisiert" },
    { key: "Samepasserr", message: "Altes Passwort und neues Passwort sind nicht gleich" },
    { key: "Contactus", message: "Abfrage erfolgreich an admin eingereicht" },
    { key: "Depsiterr", message: "Einzahlungsfehler" },
    { key: "Depsitsuccess", message: "Einzahlung erfolgreich" },
    { key: "withdrawsuccess", message: "Rückzug erfolgreich abgeschlossen" },
    { key: "Withdrawerr", message: "Rückzugsanforderung fehlgeschlagen" },
    { key: "Insuffbal", message: "Unzureichende Brieftaschenbalance" },
    { key: "Invalidcurr", message: "Ungültige Währungsanforderung" },
    { key: "Norecord", message: "Kein Eintrag gefunden" },
    { key: "success", message: "Erfolg" },
    { key: "auth", message: "Für die Authentifizierung ist ein Token erforderlich" },
    { key: "ServerError", message: "interner Serverfehler!" }
]





































































