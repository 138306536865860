import React, { useEffect } from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

const OTPVerify = ({ commonApiCall, loading, logotpRes }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onBlur", });
    const { t } = useTranslation();
    
    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    useEffect(() => {
        if (logotpRes) {
            console.log(logotpRes);
        }
    }, [logotpRes])

    const onSubmit = (data) => {
        const verify = {
            _id: localStorage.getItem("userID"),
            otp: data.tfa_code,
        };
        const Data = {
            url: "user/log_otp",
            method: "post",
            reducerName: "logotpRes",
            payload: verify,
        };
        commonApiCall(Data);
    };

    return (
        <>
            <div>
                <form className="sigin_form_wrap" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
                    <div className="form-group">
                        <label htmlFor="email">{t("EnterOTP")}</label>
                        <input
                            type="text"
                            placeholder={t("FieldOTP")}
                            id="email"
                            name="tfa_code"
                            onKeyDown={handleKeyDown}
                            {...register("tfa_code", {
                                required: t("otpreq"),
                                pattern: {
                                    value: /^[0-9]{1,4}$/,
                                    message: t("Invalid OTP")
                                }
                            })}
                        />
                        {errors.tfa_code && (
                            <p className="text-danger">{errors.tfa_code.message}</p>
                        )}
                    </div>

                    <div className="signin_submit">
                        <button
                            type="submit"
                            className="signin_btn_wrap"
                            disabled={loading}
                        >
                            {loading ? t("Loading") : t("Verify")}
                        </button>
                    </div>
                </form>
            </div>
        </>
    );
};

const mapStateToProps = (state) => ({
    signInResponse: state.signInResponse,
    loading: state.loading,
    logotpRes: state.logotpRes,
});

const mapDispatchToProps = (dispatch) => ({
    commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
    clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(OTPVerify);
