export const randomObj = {
	
	8: [
		0, 1, 1, 1, 7, 7, 7, 2, 2, 2, 2, 2, 6, 6, 6, 6, 6, 3, 4, 5, 3, 4, 5, 3, 4,
		5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5,
		3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3,
		4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4, 5, 3, 4,
		5, 4, 4, 4, 4, 4, 4, 4, 4, 4, 4
	],
	
	12: [
		0, 1, 11, 2, 2, 10, 10, 9, 9, 9, 3, 3, 3, 4, 4, 4, 4, 4, 8, 8, 8, 8, 8, 5,
		6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6,
		7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7,
		5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5,
		6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6, 7, 5, 6,
		7, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6, 6
	],
	
	16: [
		15, 14, 13, 1, 2, 3, 12, 12, 4, 4, 11, 11, 11, 5, 5, 5, 6, 6, 6, 6, 10, 10,
		10, 10, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8,
		9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9,
		7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7, 8, 9, 7,
		8, 9, 8, 8, 8, 8, 8, 8, 8, 8, 0
	]
}
