import React from "react";
import { connect } from "react-redux";
import { useForm } from "react-hook-form";
import Helper from "../Helper/Helper";
import { CustomUseContext } from "../context/ContextProvider";
import { useTranslation } from "react-i18next";

const backendAPI = Helper.backendAPI();

const VerifyMail = ({ commonApiCall, loading }) => {

    const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onBlur", });
    const { t } = useTranslation();
    const { setLoginmodal, setmailModal } = CustomUseContext();

    const handleKeyDown = (e) => {
        if (e.key === " ") {
            e.preventDefault();
        }
    };

    const onSubmit = (data) => {
        Helper.PostData(`${backendAPI}user/verify_user/${data.otp_code}`).then((response) => {
            if (response?.status) {
                setLoginmodal(true);
                setmailModal(false);
            }
        })
    };

    return (
        <form className="sigin_form_wrap" onSubmit={handleSubmit(onSubmit)} autoComplete="off">
            <div className="form-group">
                <label htmlFor="email">{t("enterRegOTP")}</label>
                <input
                    type="number"
                    className="text-center"
                    placeholder={t("FieldOTP")}
                    id="email"
                    name="otp_code"
                    onKeyDown={handleKeyDown}
                    {...register("otp_code", {
                        required: t("otpreq"),
                        pattern: {
                            value: /^[0-9]{1,4}$/,
                            message: t("Invalid OTP")
                        }
                    })}
                />
                {errors.otp_code && (
                    <p className="text-danger">* {errors.otp_code.message}</p>
                )}
            </div>

            <div className="signin_submit">
                <button
                    type="submit"
                    className="signin_btn_wrap mx-2"
                    disabled={loading}
                >
                    {loading ? t("Loading") : t("Verify")}
                </button>
                <button
                    type="button"
                    className="signin_btn_wrap mx-2"
                    disabled={loading}
                >
                    {loading ? t("Loading") : t("Re-send OTP")}
                </button>
            </div>
        </form>
    );
};

const mapStateToProps = (state) => ({
    signInResponse: state.signInResponse,
    loading: state.loading,
    logotpRes: state.logotpRes,
});

const mapDispatchToProps = (dispatch) => ({
    commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
    clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(VerifyMail);
