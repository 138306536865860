import React from "react";
import { Link } from "react-router-dom";
import hplogo from "../../assets/images/hp-logo-center.png";
import no_error from "../../assets/images/404-layer.png";
import { useTranslation } from "react-i18next";


function Nopage() {
  const { t } = useTranslation();
  document.title="404";

  return (
    <div className="no_page_bg_wrap">
      <div className="container">
        <div className="no_page_content_wrap">
          <div className="Game_logo_wrap">
            <img src={hplogo} alt="house of plinko logo" />
          </div>
          <div className="error_image_outer_wrap">
            <img src={no_error} alt="no_error" />
          </div>
          <h4>{t("Page Not Found")}</h4>
          <Link to="/">{t("Back to Home")}</Link>
        </div>
      </div>
    </div>
  );
}

export default Nopage;
