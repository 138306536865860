import React, { useState } from "react";
import { useForm } from "react-hook-form";
import { connect } from "react-redux";
import { useTranslation, I18nextProvider } from "react-i18next";
import { PageLoader } from "./shared/loader";

const Forgetpassword = (props) => {
  const { register, handleSubmit, formState: { errors }, reset } = useForm({ mode: "onBlur", });

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };
  const { t } = useTranslation();

  const onSubmit = (data) => {

    let dataa = {
      email:data?.email.toLowerCase(),
      type: "website"
    };
    const Data = {
      url: "user/forgot_user",
      method: "post",
      reducerName: "ForgotpwdRes",
      payload: dataa,
    };
    props.commonApiCall(Data);
    reset();
  };

  return (
    <>
      <PageLoader load={props.loading} />
      <div>
        <form
          className="sigin_form_wrap rest_password_wrap"
          onSubmit={handleSubmit(onSubmit)}
          autoComplete={"off"}
        >
          <h4>{t("Forgot Password")}</h4>
          <div className="form-group">
            <label htmlFor="reset_email">
              {t("Registered Email Address")}
            </label>
            <input
              type="text"
              placeholder={t("Fieldemail")}
              id="reset_email"
              name="email"
              onKeyDown={handleKeyDown}
              {...register("email", {
                required: t("emailreq"),
                pattern: {
                  value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/,
                  message: t("emailpat"),
                },
              })}
            />
            {errors.email && (
              <p className="text-danger">* {errors.email.message}</p>
            )}
          </div>
          <div className="signin_submit d-flex justify-content-center">
            <button
              type="submit"
              className="signin_btn_wrap"
            >
              {t("Send Link")}
            </button>
          </div>
        </form>
      </div>
    </>
  );
};

const mapStateToProps = (state) => ({
  ForgotpwdRes: state.ForgotpwdRes,
  loading: state.loading,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data) => dispatch({ type: "commonApiCall", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Forgetpassword);
