import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector"

// Importing translation files

import translationEN from "./English";
import translationGE from "./Germanese.json";
import translationSP from "./spanish.json";
import translationFR from "./french.json";
import translationIT from "./italian.json";

//Creating object with the variables of imported translation files
const resources = {
  en: {
    translation: translationEN,
  },
  ge: {
    translation: translationGE,
  },
  es: {
    translation: translationSP,
  },
  fr: {
    translation: translationFR,
  },
  it: {
    translation: translationIT,
  },
};

//i18N Initialization

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    resources,
    lng: localStorage.getItem("hop-lang"),
    keySeparator: false,
    interpolation: {
      escapeValue: false,
    },
  });


