import React, { useContext, useEffect } from "react";
import { useDisconnect } from "wagmi";
import { CustomUseContext } from "../context/ContextProvider";
import { SocketContext } from "../context/socket";

const Idle = () => {

    const { disconnect } = useDisconnect();
    const { setUserToken } = CustomUseContext();
    const socket = useContext(SocketContext);

    useEffect(() => {
        timecheck()
    }, [])


    document.addEventListener("mousemove", () => {
        let time = new Date().getTime();
        localStorage.setItem('hop-frnt', Number(time) + 1 * 1000 * 60 * 60)
    });
    document.addEventListener("click", () => {
        let time = new Date().getTime();
        localStorage.setItem('hop-frnt', Number(time) + 1 * 1000 * 60 * 60)
    });

    document.addEventListener('keypress', (event) => {
        let time = new Date().getTime();
        localStorage.setItem('hop-frnt', Number(time) + 1 * 1000 * 60 * 60)
    }, false);

    const timecheck = () => {

        let LastActiveTime = localStorage.getItem('hop-frnt');
        let expiretime = new Date().getTime();

        let loginToken = localStorage.getItem('hop-access-key')

        if (Number(expiretime) > Number(LastActiveTime)) {

            if (loginToken) {
                disconnect();
                localStorage.removeItem("hop-access-key");
                localStorage.removeItem("walletconnect");
                window.location.href = "/";
                socket.emit("logout", loginToken);
                socket.on("logout_get", (get_param) => {
                    if (get_param == loginToken) {
                        setUserToken(null);
                    }
                });
            }
        }

    }

    return (
        <></>
    )
}
export default Idle