export const SpanishKey = [
    { key: "Required", message: "Llene los campos requeridos" },
    { key: "Ipblock", message: "Tu IP está bloqueada" },
    { key: "Notfoundadmin", message: "Incapaz de encontrar administrador" },
    { key: "Verifytfa", message: "¡Verifique TFA para iniciar sesión!" },
    { key: "Loginsuccess", message: "Iniciar sesión exitoso " },
    { key: "invalidlogin", message: "Credencial de inicio de sesión inválido " },
    { key: "error", message: "¡¡¡Algo salió mal" },
    { key: "blocked", message: "Bloqueado ... demasiados intentos de inicio de sesión fallidos" },
    { key: "resetlink", message: "El enlace de reinicio de contraseña se envía a su correo electrónico" },
    { key: "Mailnotfound", message: "El correo electrónico no encontrado" },
    { key: "Invalidpass", message: "Confirmar contraseña no válido" },
    { key: "Resetlinkinvalid", message: "Restablecer el enlace de contraseña no es válido" },
    { key: "Resetsuccess", message: "Restablecer la contraseña correctamente" },
    { key: "Invalidpatt", message: "Patrón de confirmación no válido" },
    { key: "Resetpattinvalid", message: "El enlace del patrón de reinicio no es válido" },
    { key: "Resetpattsuccess", message: "Restablecer con éxito el patrón" },
    { key: "resetpattlink", message: "El enlace de reinicio de patrones se envía a su correo electrónico" },
    { key: "Passwordupdate", message: "Contraseña actualizada exitosamente" },
    { key: "Passwordupdatemismatch", message: "Nueva contraseña y confirmar la nueva contraseña no coincidiendo" },
    { key: "Invalidoldpass", message: "Contraseña antigua incorrecta" },
    { key: "Successpattern", message: "Patrón actualizado con éxito" },
    { key: "Pattmismatch", message: "El nuevo patrón y el patrón de confirmación deberían ser el mismo" },
    { key: "Invalidoldpatt", message: "El patrón antiguo es incorrecto" },
    { key: "Templatenotfound", message: "Plantilla de correo electrónico no encontrada" },
    { key: "updatetemp", message: "Plantilla de correo electrónico actualizada correctamente" },
    { key: "Verifytfasucc", message: "La autenticación de dos factores se verifica con éxito" },
    { key: "Verifytfaerr", message: "Código de autenticación de dos factores no válido" },
    { key: "Notfoundadmin", message: "Administrador no encontrado" },
    { key: "Tfadisable", message: "TFA discapacitado con éxito" },
    { key: "Tfaenable", message: "TFA habilitado con éxito" },
    { key: "Notfoundcms", message: "No se encontró CMS" },
    { key: "Nochanges", message: "No se producen cambios" },
    { key: "Cmsupdate", message: "CMS actualizado con éxito" },
    { key: "Mobileupdate", message: "Contenido móvil actualizado correctamente" },
    { key: "Versionupdate", message: "Versión actualizada con éxito" },
    { key: "Quesexist", message: "La pregunta ya existe" },
    { key: "Addfaq", message: "Se guardan los datos de las preguntas frecuentes" },
    { key: "Faqupdate", message: "Datos de preguntas frecuentes actualizadas correctamente" },
    { key: "FaqStatus", message: "Estado de preguntas frecuentes actualizado correctamente" },
    { key: "Faqmust", message: "Las preguntas frecuentes deben tener al menos uno de los datos" },
    { key: "Deletedata", message: "Datos eliminados con éxito" },
    { key: "Categoryexist", message: "La categoría ya existe" },
    { key: "Categorysave", message: "Categoría guardada" },
    { key: "Invalidmail", message: "¡Registre el correo de usuario no permitido!" },
    { key: "Mailexist", message: "Registre el correo electrónico ya existe" },
    { key: "Userregister", message: "Registro completado con éxito. Por favor, consulte su correo para activar su cuenta" },
    { key: "UsernameExist", message: "Nombre de usuario ya tomado por otro usuario" },
    { key: "InvalidOTP", message: "OTP incorrecto" },
    { key: "ExpiredOTP", message: "OTP ha sido expirado" },
    { key: "Verified", message: "Su cuenta ha sido confirmada. ¡Proceda a iniciar sesión!" },
    { key: "Verifieduser", message: "Usuario ya verificado" },
    { key: "Otpexpired2", message: "OTP expiró amablemente consulte su correo electrónico a New OTP" },
    { key: "Invalidlogin", message: "Credentia de inicio de sesión no válida" },
    { key: "Verifynotify", message: "La verificación de su cuenta aún no está completa, por favor verifique su correo para activar su cuenta" },
    { key: "Verifymessage", message: "Usuario, verifique su cuenta" },
    { key: "Forgotpass", message: "Olvidé la solicitud de contraseña enviada correctamente. Por favor, consulte su correo electrónico para procesar más" },
    { key: "Imageformat", message: "Seleccione solo formato JPG, JPEG, PNG" },
    { key: "Profileupdate", message: "perfil actualizado con éxito" },
    { key: "Samepasserr", message: "La contraseña anterior y la nueva contraseña no son las mismas" },
    { key: "Contactus", message: "Consulta enviada al administrador correctamente" },
    { key: "Depsiterr", message: "Error de depósito" },
    { key: "Depsitsuccess", message: "Depósito exitoso" },
    { key: "withdrawsuccess", message: "Retirarse completado con éxito" },
    { key: "Withdrawerr", message: "La solicitud de retiro fallida" },
    { key: "Insuffbal", message: "Balance de billetera insuficiente" },
    { key: "Invalidcurr", message: "Solicitud de moneda no válida" },
    { key: "Norecord", message: "Ningún record fue encontrado" },
    { key: "success", message: "éxito" },
    { key: "auth", message: "Se requiere un token para la autenticación" },
    { key: "ServerError", message: "Error Interno del Servidor!" }
]





































































