export const ItalienKey = [
    { key: "Required", message: "Si prega di riempire i campi richiesti" },
    { key: "Ipblock", message: "Il tuo IP è bloccato" },
    { key: "Notfoundadmin", message: "Impossibile trovare l'amministratore" },
    { key: "Verifytfa", message: "Si prega di verificare che TFA login " },
    { key: "Loginsuccess", message: "Accedi di successo " },
    { key: "invalidlogin", message: "Credenziale di accesso non valido " },
    { key: "error", message: "Qualcosa è andato storto" },
    { key: "blocked", message: "Bloccato ... troppi tentativi di accesso falliti" },
    { key: "resetlink", message: "Il link reimpostazione della password viene inviato alla tua email" },
    { key: "Mailnotfound", message: "E -mail non trovata" },
    { key: "Invalidpass", message: "Conferma non valida la password" },
    { key: "Resetlinkinvalid", message: "Reimpostare il collegamento password non è valido" },
    { key: "Resetsuccess", message: "Reimpostazione della password correttamente" },
    { key: "Invalidpatt", message: "Modello di conferma non valido" },
    { key: "Resetpattinvalid", message: "Il collegamento del modello di ripristino non è valido" },
    { key: "Resetpattsuccess", message: "Ripristino del pattern correttamente" },
    { key: "resetpattlink", message: "Il collegamento di ripristino del pattern viene inviato alla tua email" },
    { key: "Passwordupdate", message: "Password aggiornata correttamente" },
    { key: "Passwordupdatemismatch", message: "Nuova password e conferma nuova password non corrispondente" },
    { key: "Invalidoldpass", message: "Password vecchia errata" },
    { key: "Successpattern", message: "Modello aggiornato correttamente" },
    { key: "Pattmismatch", message: "Nuovo modello e il modello di conferma dovrebbe essere lo stesso" },
    { key: "Invalidoldpatt", message: "Il vecchio modello non è corretto" },
    { key: "Templatenotfound", message: "Modello di posta elettronica non trovato" },
    { key: "updatetemp", message: "Modello di posta elettronica aggiornato correttamente" },
    { key: "Verifytfasucc", message: "L'autenticazione a due fattori viene verificata con successo" },
    { key: "Verifytfaerr", message: "Codice di autenticazione a due fattori non valido" },
    { key: "Notfoundadmin", message: "Amministratore non trovato" },
    { key: "Tfadisable", message: "TFA disabilitato correttamente" },
    { key: "Tfaenable", message: "TFA abilitato correttamente" },
    { key: "Notfoundcms", message: "Nessun CMS trovato" },
    { key: "Nochanges", message: "Non si verificano cambiamenti" },
    { key: "Cmsupdate", message: "CMS aggiornato con successo" },
    { key: "Mobileupdate", message: "Contenuti mobili aggiornati correttamente" },
    { key: "Versionupdate", message: "Versione aggiornata correttamente" },
    { key: "Quesexist", message: "La domanda esiste già" },
    { key: "Addfaq", message: "I dati FAQ vengono salvati" },
    { key: "Faqupdate", message: "Dati FAQ aggiornati correttamente" },
    { key: "FaqStatus", message: "Stato FAQ aggiornato correttamente" },
    { key: "Faqmust", message: "Le FAQ devono avere almeno un dati" },
    { key: "Deletedata", message: "Dati eliminati correttamente" },
    { key: "Categoryexist", message: "La categoria esiste già" },
    { key: "Categorysave", message: "Categoria salvata" },
    { key: "Invalidmail", message: "Registra la posta dell'utente non consentita " },
    { key: "Mailexist", message: "Registra email esistono già" },
    { key: "Userregister", message: "Registrazione completata con successo. Si prega di controllare la posta per attivare il tuo account" },
    { key: "UsernameExist", message: "Nome utente già preso da un altro utente" },
    { key: "InvalidOTP", message: "OTP errato" },
    { key: "ExpiredOTP", message: "OTP è stato scaduto" },
    { key: "Verified", message: "Il tuo account è stato confermato. Si prega di procedere al login " },
    { key: "Verifieduser", message: "Utente già verificato" },
    { key: "Otpexpired2", message: "OTP è scaduto gentilmente controlla la tua email a un nuovo OTP" },
    { key: "Invalidlogin", message: "Credentia di accesso non valido" },
    { key: "Verifynotify", message: "La verifica del tuo account non è ancora completa, controlla gentilmente la posta per attivare il tuo account" },
    { key: "Verifymessage", message: "Utente Verifica il tuo account" },
    { key: "Forgotpass", message: "Richiesta di password dimenticata inviata correttamente. Si prega di controllare la tua email per ulteriori elaborazioni" },
    { key: "Imageformat", message: "Seleziona JPG, JPEG, solo formato PNG" },
    { key: "Profileupdate", message: "Profilo aggiornato con successo" },
    { key: "Samepasserr", message: "Vecchia password e nuova password non essere uguali" },
    { key: "Contactus", message: "Query inviata all'amministratore con successo" },
    { key: "Depsiterr", message: "Errore di deposito" },
    { key: "Depsitsuccess", message: "Depositare successo" },
    { key: "withdrawsuccess", message: "Ritiro completato con successo" },
    { key: "Withdrawerr", message: "Richiesta di prelievo non riuscita" },
    { key: "Insuffbal", message: "Equilibrio del portafoglio insufficiente" },
    { key: "Invalidcurr", message: "Richiesta di valuta non valida" },
    { key: "Norecord", message: "Nessun record trovato" },
    { key: "success", message: "successo" },
    { key: "auth", message: "È necessario un token per l'autenticazione" },
    { key: "ServerError", message: "Errore interno del server!" }
]





































































