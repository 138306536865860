import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { CustomUseContext } from "./context/ContextProvider";

const PrivateRoute = () => {

  const { setLoginmodal } = CustomUseContext();
  const loginToken = localStorage.getItem("hop-access-key");

  if (!loginToken) {
    setLoginmodal(true);
    return <Navigate to="/" />;
  } else {
    return <Outlet />;
  }
};

export default PrivateRoute;
