export default function payoutValue(rows, risk, num) {

  let value = {
    8: {
      low: [
        [true, num, 7.3, "bet"],
        [true, num, 1.9, "bet"],
        [true, num, 1.4, "bet"],
        [false, num, 0.9, "bet"],
        [false, num, 0.4, "bet"],
        [false, num, 0.9, "bet"],
        [true, num, 1.4, "bet"],
        [true, num, 1.9, "bet"],
        [true, num, 7.3, "bet"],
      ],

      medium: [
        [true, num, 16, "bet"],
        [true, num, 3.7, "bet"],
        [true, num, 1.5, "bet"],
        [false, num, 0.5, "bet"],
        [false, num, 0.3, "bet"],
        [false, num, 0.5, "bet"],
        [true, num, 1.5, "bet"],
        [true, num, 3.7, "bet"],
        [true, num, 16, "bet"],
      ],

      high: [
        [true, num, 21, "bet"],
        [true, num, 7.1, "bet"],
        [false, num, 1.0, "bet"],
        [false, num, 0.3, "bet"],
        [false, num, 0.1, "bet"],
        [false, num, 0.3, "bet"],
        [false, num, 1.0, "bet"],
        [true, num, 7.1, "bet"],
        [true, num, 21, "bet"],
      ],
    },

    12: {
      low: [
        [true, num, 10, "bet"],
        [true, num, 3.1, "bet"],
        [true, num, 1.8, "bet"],
        [true, num, 1.5, "bet"],
        [true, num, 1.1, "bet"],
        [false, num, 1.0, "bet"],
        [false, num, 0.4, "bet"],
        [false, num, 1.0, "bet"],
        [true, num, 1.1, "bet"],
        [true, num, 1.5, "bet"],
        [true, num, 1.8, "bet"],
        [true, num, 3.1, "bet"],
        [true, num, 10, "bet"],
      ],

      medium: [
        [true, num, 22, "bet"],
        [true, num, 4.2, "bet"],
        [true, num, 3.0, "bet"],
        [true, num, 2.0, "bet"],
        [true, num, 1.4, "bet"],
        [false, num, 0.6, "bet"],
        [false, num, 0.3, "bet"],
        [false, num, 0.6, "bet"],
        [true, num, 1.4, "bet"],
        [true, num, 2.0, "bet"],
        [true, num, 3.0, "bet"],
        [true, num, 4.2, "bet"],
        [true, num, 22, "bet"],
      ],

      high: [
        [true, num, 27, "bet"],
        [true, num, 8.1, "bet"],
        [true, num, 4.6, "bet"],
        [true, num, 3.1, "bet"],
        [true, num, 1.1, "bet"],
        [false, num, 0.4, "bet"],
        [false, num, 0.1, "bet"],
        [false, num, 0.4, "bet"],
        [true, num, 1.1, "bet"],
        [true, num, 3.1, "bet"],
        [true, num, 4.6, "bet"],
        [true, num, 8.1, "bet"],
        [true, num, 27, "bet"],
      ],
    },

    16: {
      low: [
        [true, num, 13, "bet"],
        [true, num, 7.5, "bet"],
        [true, num, 1.8, "bet"],
        [true, num, 1.7, "bet"],
        [true, num, 1.6, "bet"],
        [true, num, 1.2, "bet"],
        [true, num, 1.1, "bet"],
        [false, num, 1.0, "bet"],
        [false, num, 0.4, "bet"],
        [false, num, 1.0, "bet"],
        [true, num, 1.1, "bet"],
        [true, num, 1.2, "bet"],
        [true, num, 1.6, "bet"],
        [true, num, 1.7, "bet"],
        [true, num, 1.8, "bet"],
        [true, num, 7.5, "bet"],
        [true, num, 13, "bet"],
      ],

      medium: [
        [true, num, 25, "bet"],
        [true, num, 9.6, "bet"],
        [true, num, 6.3, "bet"],
        [true, num, 4.0, "bet"],
        [true, num, 1.9, "bet"],
        [true, num, 1.7, "bet"],
        [true, num, 1.4, "bet"],
        [false, num, 0.5, "bet"],
        [false, num, 0.2, "bet"],
        [false, num, 0.5, "bet"],
        [true, num, 1.4, "bet"],
        [true, num, 1.7, "bet"],
        [true, num, 1.9, "bet"],
        [true, num, 4.0, "bet"],
        [true, num, 6.3, "bet"],
        [true, num, 9.6, "bet"],
        [true, num, 25, "bet"],
      ],

      high: [
        [true, num, 80, "bet"],
        [true, num, 26, "bet"],
        [true, num, 10, "bet"],
        [true, num, 5.6, "bet"],
        [true, num, 3.2, "bet"],
        [true, num, 2.0, "bet"],
        [true, num, 1.1, "bet"],
        [false, num, 0.3, "bet"],
        [false, num, 0.1, "bet"],
        [false, num, 0.3, "bet"],
        [true, num, 1.1, "bet"],
        [true, num, 2.0, "bet"],
        [true, num, 3.2, "bet"],
        [true, num, 5.6, "bet"],
        [true, num, 10, "bet"],
        [true, num, 26, "bet"],
        [true, num, 80, "bet"],
      ],
    },
  };

  let payout_value = value[String(rows)][risk].map((item) => item[2]);

  return { result: value[String(rows)][risk][num], payout_value };
}

export const getExactPosition = (position, rowws, number) => {
  let exactPostion = {
    8: [0, 1, 2, 3, 4, 5, 6, 7, 8],
    12: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
    16: [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
  };

  if (position < 0) {
    let arr = exactPostion[String(rowws)];
    return arr[arr?.length - number - 1];
  }
  return number;
};
