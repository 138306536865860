import React, { useContext } from "react";

import payoutValue from "./payout_value";

import { nanoid } from "nanoid";
import Helper from "../../Helper/Helper";

export default function placeBet(
  num,
  row,
  risk,
  amount,
  updatedBalanceforbackend,
  gameId,
  userId,
  cid,
  row_id,
  risk_id,
  name,
  decimal,
  callback
) {
  let risks = ["low", "medium", "high"];

  let { result, payout_value } = payoutValue(
    row,
    risks[risk - 1],
    num,
    (payoutTable) => (payoutTable = payoutTable)
  );
  let profit = result[0]
    ? result[2] * amount - amount
    : amount - result[2] * amount;


  let inData = {
    insdata: {
      bet_id: Math.round(
        window.performance.timing.navigationStart + window.performance.now()
      ),
      game_id: gameId,
      user_id: userId,
      bet_amount: amount,
      payout: result[2].toFixed(1),
      profit,
      profit_prediction: result[0],
      game_name: "Plinko",
      row_id,
      risk_id,
      cid,
      payouts: payout_value,
      username: name,
      created_at: new Date(),
      payout_position: num,
      from: "fe",
    },
    bal: { amount: updatedBalanceforbackend + result[2] * amount, cid: cid },
  };

  callback(
    {
      won: result[0],
      resultCol: result[1],
      userProfit: result[2],
      betNum: result[3],
      backendBalance: updatedBalanceforbackend + result[2] * amount,
      profit,
    },
    inData
  );
}
