import React, { useEffect, useRef, useState } from "react";
import game_img from "../../assets/images/delete_accoun.webp";
import { connect } from "react-redux";
import { useTranslation } from "react-i18next";
import { PageLoader } from "../shared/loader";

function DeleteAcc({ commonApiCall }) {

    const [showContent, setShowContent] = useState();
    const { t } = useTranslation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        window.scrollTo(0, 0)
        document.title = `HouseofPlinko | ${t("Delete Account")}`;

        const Data1 = {
            url: "getcms",
            method: "get",
            reducerName: "getAboutus",
        };
        commonApiCall(Data1, (response) => {
            if (response) {
                setShowContent(response.data[0]?.content);
                setLoading(false);
            }
        });
    }, []);


    return (
        <><PageLoader load={loading} />
            <div className="minHght">
                <div className="cms_section_outer_wrap col-md-8">
                    <div className="text-center my-5">
                        <h2>{t("Delete Account")}</h2>
                    </div>
                    <div className="row p-md-5 pilnko_tabs_outer_wrap">
                        <div className="col-lg-8">
                            <p dangerouslySetInnerHTML={{ __html: showContent }}></p>
                        </div>
                        <div className="col-lg-4">
                            <div className="cms_game_img">
                                <img src={game_img} alt="game_img" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
}

const mapDispatchToProps = (dispatch) => ({
    commonApiCall: (data, callback) => dispatch({ type: "commonApiCall", data, callback }),
});

export default connect(null, mapDispatchToProps)(DeleteAcc);
