import React from "react";
import Lottie from "lottie-react";
import ColorLoader from './load.json';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import 'react-loading-skeleton/dist/skeleton.css'
import { CustomUseContext } from "../../context/ContextProvider";
import { Modal, ModalBody } from "reactstrap";
import { useTranslation } from "react-i18next";

export const PageLoader = (props) => {

    const { t } = useTranslation();

    return (
        <div className={`loader ${props.load ? "active" : ""}`}>
            <div className="pageLoader">
                <div className="load">
                    <Lottie animationData={ColorLoader} loop={true} style={{ width: "150px" }} />
                    <div className='thumbnail'>
                        <p>{t("Please wait")} <span className='inner'>. . . . .</span></p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export const ContractLoader = (props) => {

    const { loaderMessage } = CustomUseContext();
    const { t } = useTranslation();

    return (
        <Modal
            isOpen={props.load}
            backdrop={false}
            size="sm"
            className="model_common_style_wrap" centered
        >
            <ModalBody>
                <div className="bet_slip_outer_wrap text-center">
                    <h3>{loaderMessage ? t(loaderMessage) : t("LOADING")}</h3>
                    <p>{t("waiting_content")}</p>
                    <Lottie animationData={ColorLoader} loop={true} style={{ width: "100px", margin: "0 auto" }} />
                    <div className='thumbnail2'>
                        <p>{t("Please wait")}<span className='inner'>. . . . .</span></p>
                    </div>
                </div>
            </ModalBody>
        </Modal>
    )
}

export const ProfileLoader = () => {
    return (
        <SkeletonTheme baseColor="#161420" highlightColor="#2a263f" borderRadius={12}>
            <div className="profile_content_outer_wrap wallet_content_outer_wrap">
                <div className="wallet_content_inner_wrap">
                    <div className="p-5 d-flex">
                        <Skeleton height={150} width={150} className="rounded-circle mx-4" />
                        <Skeleton height={50} width={150} count={3} />
                    </div>
                    <div className="p-5">
                        <Skeleton width={300} height={300} />
                    </div>
                </div>
            </div>
        </SkeletonTheme>
    )
}

export const ButtonLoader = () => {
    const { t } = useTranslation();
    return (
        <div style={{ color: '#ffce2e', fontWeight: "bold" }}>
            <small>{t("LOADING")} . . . . .</small>
        </div>
    )
}