import Axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { EnglishKey } from "../Language/Notification/English";
import { FrenchKey } from "../Language/Notification/french";
import { GermanKey } from "../Language/Notification/german";
import { ItalienKey } from "../Language/Notification/italy";
import { SpanishKey } from "../Language/Notification/spanish";

import CryptoJS from "crypto-js";
import moment from "moment";


let axiosConfig;

const Helper = {
  backendAPI: function () {
    let url = "https://backapilive.houseofplinko.games/";
    return url;
  },
  googleAPIKey: function () {
    let key = "AIzaSyA0xMmSRK63MapNZr8fVxTK9MsB3DVBhig";
    return key;
  },
  getBottoken: function () {
    let bottoken = "6676287607:AAHByP6GdTs4K3iBep_Rdp_Jwsz07pBmc7g"
    return bottoken
  },

  PostData: async function (url, params, type) {

    let APIToken = await Helper?.signToken({ issuer: 'wvUbpxkGxPyAe', exp: (moment().unix() + 5 * 60) * 1000 })


    if (localStorage.getItem("hop-access-key")) {
      axiosConfig = {
        headers: {
          "Access-Control-Allow-Origin": "true",
          "x-access-token": localStorage.getItem("hop-access-key"),
          "kZFwskpVtGfvpk": APIToken

        },
      };
    } else {
      axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "true",
          "kZFwskpVtGfvpk": APIToken

        },
      };
    }

    return await Axios.post(url, params, axiosConfig)
      .then((res) => {
        try {
          if (res.status === 200) {
            let output = res.data;
            if (output.status === true) {
              CustomNotify(output, "success")
              return output;
            } else {
              CustomNotify(output, "error")
            }
            return output;
          }
        } catch (e) {
          CustomNotify({ key: "ServerError", message: "Internal server error!" }, "error")
        }
      })
      .catch((e) => {
        CustomNotify({ key: "ServerError", message: "Internal server error!" }, "error")
        localStorage.removeItem("hop-access-key");
      });
  },

  GetData: async function (url) {

    let APIToken = await Helper?.signToken({ issuer: 'wvUbpxkGxPyAe', exp: (moment().unix() + 5 * 60) * 1000 })


    if (localStorage.getItem("hop-access-key")) {
      axiosConfig = {
        headers: {
          "Access-Control-Allow-Origin": "true",
          "x-access-token": localStorage.getItem("hop-access-key"),
          "kZFwskpVtGfvpk": APIToken

        },
      };
    } else {
      axiosConfig = {
        headers: {
          "Content-Type": "application/json;charset=UTF-8",
          "Access-Control-Allow-Origin": "true",
          "kZFwskpVtGfvpk": APIToken

        },
      };
    }

    return Axios.get(url, axiosConfig)
      .then((res) => res.data)
      .catch((e) => {
        if (e.response.status === 401) {
          localStorage.removeItem("hop-access-key");
          window.location.href = "/";
        } else {
          return { status: false, message: e };
        }
      });
  },

  formatDated: function (date) {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var dates = date.getDate();
    var months = date.getMonth() + 1;
    var years = date.getFullYear();
    var ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    dates = dates < 10 ? "0" + dates : dates;
    months = months < 10 ? "0" + months : months;
    var strTime = hours + ":" + minutes + ":" + seconds + " " + ampm;
    return dates + "/" + months + "/" + years + " " + strTime;
  },
  formatDated1: function (date) {
    date = new Date(date);
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var seconds = date.getSeconds();
    var dates = date.getDate();
    var months = date.getMonth() + 1;
    var years = date.getFullYear();
    hours = hours % 12;
    hours = hours ? hours : 12;
    hours = hours < 10 ? "0" + hours : hours;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    seconds = seconds < 10 ? "0" + seconds : seconds;
    dates = dates < 10 ? "0" + dates : dates;
    months = months < 10 ? "0" + months : months;
    return years + "-" + months + "-" + dates;
  },

  //  INTERCEPTOR

  base64url: (source) => { let encodedSource = CryptoJS.enc.Base64.stringify(source); return encodedSource; },
  encodeToken: (payload) => {
    var header = { "alg": "HS256", "typ": "JWT" };
    var stringifiedHeader = CryptoJS.enc.Utf8.parse(JSON.stringify(header)); var encodedHeader = Helper?.base64url(stringifiedHeader);
    var stringifiedData = CryptoJS.enc.Utf8.parse(JSON.stringify(payload)); var encodedData = Helper?.base64url(stringifiedData);
    return encodedHeader + "." + encodedData;
  },
  signToken: (payload) => {
    let token = Helper?.encodeToken(payload);
    var signature = CryptoJS.HmacSHA256(token, "wvUbpxkGxPyAe"); signature = Helper?.base64url(signature);
    var signedToken = token + "." + signature; return signedToken;
  },


  isValidFile: async function (event, files, type) {
    return new Promise((resolve, reject) => {
      var reader = new FileReader(); reader.readAsArrayBuffer(event?.target?.files[0]); reader.onloadend = (e) => {
        var bytes = new Uint8Array(e.target.result).subarray(0, 4);
        let header = '';
        for (let i = 0; i < bytes.length; i++) {
          header += bytes[i].toString(16);
        }
        if ((bytes[0] != 0xFF && bytes[1] != 0xD8 && bytes[0] != 0x89 && bytes[1] != 0x50 && bytes[0] != 0x00 && bytes[0] != 0x3C && bytes[1] != 0x73) || (event.target.files && event.target.files[0].type != 'image/png' && event.target.files[0].type != 'image/jpg' && event.target.files[0].type != 'image/jpeg' && event.target.files[0].type != 'image/svg+xml' && event.target.files[0].type != 'image/avif')) {
          toast.error('Invalid image format !');
        } else {
          const img = new Image(); img.src = URL.createObjectURL(event.target.files[0]);
          img.onload = () => {
            const width = img.naturalWidth;
            const height = img.naturalHeight;
            if (width > 6000 || height > 6000) {
              toast.error('The image dimensions should be 6000 x 6000 pixels');
              resolve(false);
            }
            else {
              if (event.target.files && event.target.files[0]) {
                var file = files;
                let typeFile = file.type;
                let file_size = file.size / 1024 / 1024;
                if (typeFile != 'image/png' && typeFile != 'image/jpg' && typeFile != 'image/jpeg' && typeFile != 'image/svg+xml' && typeFile != 'image/avif') {
                  toast.error('Invalid image format !')
                }
                else if (file_size >= 1) {
                  toast.error('Upload image should be less than or equal to 1MB');
                  resolve(false);
                }
                else {
                  resolve(true)
                }
              }
            }
          };
        }
      }
    })
  },

  getSiteKey: () => { return "6Lc-dF0dAAAAAN8BHs_ZqXBKYz4JnvJ5JSg-nXcl" },
};




export default Helper;


export const CustomNotify = (output, type) => {

  var useLang;
  if (!output.key) return

  if (localStorage.getItem("hop-lang") === "en") {
    useLang = EnglishKey.find((Language) => Language.key === output?.key)
  } else if (localStorage.getItem("hop-lang") === "fr") {
    useLang = FrenchKey.find((Language) => Language.key === output?.key)
  } else if (localStorage.getItem("hop-lang") === "ge") {
    useLang = GermanKey.find((Language) => Language.key === output?.key)
  } else if (localStorage.getItem("hop-lang") === "it") {
    useLang = ItalienKey.find((Language) => Language.key === output?.key)
  } else if (localStorage.getItem("hop-lang") === "es") {
    useLang = SpanishKey.find((Language) => Language.key === output?.key)
  } else toast(output.message, { type: type });

  if (useLang) toast(useLang?.message, { type: type });
}