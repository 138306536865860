import React, { useState, useEffect } from "react";

import { useForm } from "react-hook-form";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash, faEye, faCopy } from "@fortawesome/free-solid-svg-icons";
import { connect } from "react-redux";
import { toast } from "react-toastify";
import noQrimg from '../assets/qrloader.gif';
import { useTranslation } from "react-i18next";

import { CustomUseContext } from "../context/ContextProvider";
import { PageLoader } from "./shared/loader";

const Security = (props) => {

  const { register, handleSubmit, formState: { errors }, getValues, reset } = useForm({ mode: "onTouched", });
  const { register: register1, handleSubmit: handleSubmit1, formState: { errors: errors1 }, reset: reset1, setValue: setValue1 } = useForm({ mode: "onTouched" });

  const { t } = useTranslation();

  const { setUserToken } = CustomUseContext();
  const [passwordShown, setPasswordShown] = useState(false);
  const [passwordShown1, setPasswordShown1] = useState(false);
  const [passwordShown2, setPasswordShown2] = useState(false);

  const togglePasswordVisiblity = (val) => {
    if (val === "oldpwd") {
      setPasswordShown(passwordShown ? false : true);
    } else if (val === "newpwd") {
      setPasswordShown1(passwordShown1 ? false : true);
    } else if (val === "cpwd") {
      setPasswordShown2(passwordShown2 ? false : true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === " ") {
      e.preventDefault();
    }
  };

  useEffect(() => {
    const Data = {
      url: "user/getUserdetails",
      method: "post",
      reducerName: "getTfaRes",
    };

    if (props.enableRes?.status) {
      props.commonApiCall(Data);
    }

    if (props.linkRes?.status) {
      props.commonApiCall(Data);
    }

    if (props?.userData?.secretky) {
      setValue1("tfa_password", props?.userData?.secretky)
    }

  }, [props.enableRes, props.disableRes, props.linkRes, props.userData]);


  const onSubmit = (data) => {
    const Data = {
      url: "user/changePassworduser",
      method: "post",
      reducerName: "ChngpwdRes",
      payload: data,
    };
    props.commonApiCall(Data, (response) => {
      if (response) {
        if (response.status) {
          // localStorage.removeItem("hop-access-key");
          setUserToken("");
          window.location.href = "/";
        }
      }
    });
    reset();
  };

  const TfaonSubmit = (data) => {
    if (props.userData?.randcode === "disable") {
      const Data = {
        url: "user/tfaenable",
        method: "post",
        reducerName: "enableRes",
        payload: data,
      };
      props.commonApiCall(Data, (response) => {
        if (response) {
          props.getUserProfileData();
          reset1();
        }
      });
    } else {
      const Data = {
        url: "user/disableTfa",
        method: "post",
        reducerName: "disableRes",
        payload: data,
      };
      props.commonApiCall(Data, (response) => {
        if (response) {
          props.getUserProfileData();
          reset1();
        }
      });
    }
  };

  return (
    <div className="security_section_wrap animated_div">

      <PageLoader load={props?.loading} />
      <div className="row">
        <div className="col-lg-5">
          <h3>{t("Change Password")}</h3>
          <div className="change_password_outer_wrap">
            <form
              className="sigin_form_wrap"
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="off"
            >
              <div className="form-group">
                <div className="password_outer_wrap">
                  <label htmlFor="Old_Password">{t("oldPass")}</label>
                </div>
                <div className="forgot_input_outer_wrap">
                  <input
                    type={passwordShown ? "text" : "password"}
                    placeholder={t("FieldOldpass")}
                    id="Old_Password"
                    name="old_password"
                    {...register("old_password", {
                      required: t("oldpassreq"),
                    })}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="show-button">
                    {passwordShown ? (
                      <FontAwesomeIcon
                        className="eyeIcon"
                        style={{ cursor: "pointer" }}
                        onClick={() => togglePasswordVisiblity("oldpwd")}
                        icon={faEye}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="eyeIcon"
                        icon={faEyeSlash}
                        style={{ cursor: "pointer" }}
                        onClick={() => togglePasswordVisiblity("oldpwd")}
                      />
                    )}
                  </div>
                  {errors.old_password && (
                    <span className="text-danger">
                      {errors.old_password.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="password_outer_wrap">
                  <label htmlFor="New_Password">{t("newPass")}</label>
                </div>
                <div className="forgot_input_outer_wrap">
                  <input
                    type={passwordShown1 ? "text" : "password"}
                    placeholder={t("FieldNewpass")}
                    id="New_Password"
                    name="new_password"
                    {...register("new_password", {
                      validate: (value) => value !== getValues("old_password") || t("oldpass_same"),
                      required: t("newpassreq"),
                      pattern: {
                        value: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})*/,
                        message: t("newpassmin"),
                      },
                    })}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="show-button">
                    {passwordShown1 ? (
                      <FontAwesomeIcon
                        className="eyeIcon"
                        style={{ cursor: "pointer" }}
                        onClick={() => togglePasswordVisiblity("newpwd")}
                        icon={faEye}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="eyeIcon"
                        icon={faEyeSlash}
                        style={{ cursor: "pointer" }}
                        onClick={() => togglePasswordVisiblity("newpwd")}
                      />
                    )}
                  </div>
                  {errors.new_password && (
                    <span className="text-danger">
                      {errors.new_password.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="form-group">
                <div className="password_outer_wrap">
                  <label htmlFor="Confirm_Password">
                    {t("Confirm Password")}
                  </label>
                </div>
                <div className="forgot_input_outer_wrap">
                  <input
                    type={passwordShown2 ? "text" : "password"}
                    placeholder={t("Field Confirm Password")}
                    id="Confirm_Password"
                    name="confirm_password"
                    {...register("confirm_password", {
                      validate: (value) =>
                        value === getValues("new_password") || t("passmatch"),
                      required: t("confirmpass"),
                    })}
                    onKeyDown={handleKeyDown}
                  />
                  <div className="show-button">
                    {passwordShown2 ? (
                      <FontAwesomeIcon
                        className="eyeIcon"
                        style={{ cursor: "pointer" }}
                        onClick={() => togglePasswordVisiblity("cpwd")}
                        icon={faEye}
                      />
                    ) : (
                      <FontAwesomeIcon
                        className="eyeIcon"
                        icon={faEyeSlash}
                        style={{ cursor: "pointer" }}
                        onClick={() => togglePasswordVisiblity("cpwd")}
                      />
                    )}
                  </div>
                  {errors.confirm_password && (
                    <span className="text-danger">
                      {errors.confirm_password.message}
                    </span>
                  )}
                </div>
              </div>
              <div className="signin_submit change_password_submit_wrap">
                <button type="submit" className="signin_btn_wrap">
                  {t("Submit")}
                </button>
              </div>
            </form>
          </div>
        </div>


        <div className="col-lg-7">
          <h3>{t("Two-factor Authentication")}</h3>
          <div className="change_password_outer_wrap">
            <form
              className="sigin_form_wrap"
              onSubmit={handleSubmit1(TfaonSubmit)}
              autoComplete="off"
            >
              <p>{t("TfaAuth")}</p>
              <div className="row">
                {props.userData?.randcode === "enable" ? (
                  <div className="form-group">
                    <div className="password_outer_wrap">
                      <label htmlFor="verification">
                        {t("Verification code")}
                      </label>
                    </div>
                    <div className="forgot_input_outer_wrap">
                      <input
                        type="number"
                        placeholder="Enter Six Digit Code"
                        id="verification"
                        name="tfa_code"
                        {...register1("tfa_code", {
                          required: t("Tfareq"),
                          pattern: /^(\+\d{1,3}[- ]?)?\d{6}$/,
                        })}
                      />
                      {errors1.tfa_code && (
                        <div className="form-error text-danger">
                          {errors1.tfa_code.message}
                        </div>
                      )}
                      {errors1?.tfa_code?.type === "pattern" && (
                        <div className="form-error text-danger">
                          {t("Tfapatt")}
                        </div>
                      )}
                    </div>
                  </div>
                ) : (
                  <>
                    <div className="col-xl-4 col-md-4">
                      <div className="secrity_qr_wrap">
                        <img src={props.userData?.qr_code || noQrimg} alt="qr" />
                      </div>
                    </div>
                    <div className="col-xl-8 col-md-8">
                      <div className="security_key_outer_wrap">
                        <div className="form-group">
                          <div className="password_outer_wrap">
                            <label htmlFor="security">
                              {t("Your secret key")}
                            </label>
                          </div>
                          <div className="forgot_input_outer_wrap">
                            <input
                              type="text"
                              placeholder="Enter Your secret key"
                              id="security"
                              name="tfa_password"
                              {...register1("tfa_password", {
                                required: t("Tfasecretreq"),
                              })}
                              readOnly
                            />
                            {errors1.tfa_password && (
                              <div className="form-error text-danger">
                                {errors1.tfa_password.message}.
                              </div>
                            )}
                            <FontAwesomeIcon
                              className="show-button"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                navigator.clipboard.writeText(
                                  props.userData?.secretky
                                );
                                toast.success(t("Tfaseccopy"));
                              }}
                              icon={faCopy}
                            />
                          </div>
                        </div>
                        <div className="form-group">
                          <div className="password_outer_wrap">
                            <label htmlFor="verification">
                              {t("Verification code")}
                            </label>
                          </div>
                          <div className="forgot_input_outer_wrap">
                            <input
                              type="number"
                              placeholder="Enter Six Digit Code"
                              id="verification"
                              name="tfa_code"
                              {...register1("tfa_code", {
                                required: t("Tfareq"),
                                pattern: /^(\+\d{1,3}[- ]?)?\d{6}$/,
                              })}
                            />
                            {errors1.tfa_code && (
                              <div className="form-error text-danger">
                                {errors1.tfa_code.message}
                              </div>
                            )}
                            {errors1?.tfa_code?.type === "pattern" && (
                              <div className="form-error text-danger">
                                {t("Tfapatt")}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className="signin_submit">
                  <button type="submit" className="signin_btn_wrap">
                    {props.userData?.randcode !== "enable"
                      ? t("Enable")
                      : t("Disable")}
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  ChngpwdRes: state.ChngpwdRes,
  getprofile: state.getprofile,
  getTfaRes: state.getTfaRes,
  enableRes: state.enableRes,
  disableRes: state.disableRes,
  loading: state.loading,
  linkRes: state.linkRes,
});

const mapDispatchToProps = (dispatch) => ({
  commonApiCall: (data, callback) =>
    dispatch({ type: "commonApiCall", data, callback }),
  clearstate: (data) => dispatch({ type: "clearState", data }),
});

export default connect(mapStateToProps, mapDispatchToProps)(Security);
